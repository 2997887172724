import { FETCH_GARAGEM_LAYOUT } from './../actions/types';

const INITIAL_VALUE = {
    garagemId: null,
    siteId: null,
    layout: {
        banner_desktop: null,
        banner_mobile: null,
        logo: null, 
        cor_principal: null,
        cor_secundaria: null,
        cor_texto: null,
        cor_texto_ativo: null,
        template: null,
        enderecoPrincipal: null  
    },
    institucional: {
        imagem: null,
        texto: null,
        titulo: null,
    },
    contato: {
        titulo: null,
        texto: null,
        form: null,
        email: null,
        contatato: false
    },
    redes_sociais: null,
    unidades: null,
    template_type: null,
};

export default (state = INITIAL_VALUE, action) => {
    const { payload }  = action;

    switch(action.type){
        case FETCH_GARAGEM_LAYOUT:
            return { ...state,
                garagemId: payload.planoContrato.id,
                siteId: payload.siteId,
                layout: {
                    banner_desktop: payload.urlCapaDesktop,
                    banner_mobile: payload.urlCapaMobile,
                    planoInfo: payload.planoContrato || {},
                    enderecoPrincipal: payload.planoContrato.unidadePrincipal,
                    logo: payload.urlLogo,
                    cor_principal: payload.corPrincipal,
                    cor_secundaria: payload.corSecundaria,
                    cor_texto: payload.corTexto,
                    cor_texto_botao: payload.corTextoBotao,
                    cor_texto_ativo: payload.corTextoAtivo,
                    template: payload.siteTemplate ? payload.siteTemplate.template : 'card',
                    redesSociais: payload.redeSociais
                },
                contato: {
                    titulo: payload.tituloContato,
                    texto: payload.textoContato,
                    email: payload.emailContato
                },
                institucional: {
                    imagem: payload.urlFotoInstitucional,
                    titulo: payload.tituloInstitucional,
                    texto: payload.textoInstitucional
                },
                unidades: payload.planoContrato.unidades || []
            }
        default:
            return state;
    }
};