import React, { useEffect, useState } from 'react';

import { Hidden } from '@material-ui/core';
import TopbarWithLogo from './topbar-with-logo';
import { TopbarContainer, TopbarMenu, TopbarMenuItem, TopbarDivider } from './topbar-styled';
import TopbarMobile from './topbar-mobile';
import TopbarSimple from './topbar-simple';
import { useLocation } from 'react-router-dom';
import Scroll, { scroller } from 'react-scroll';

const MenuOptions = ({ isSubdomain, textColor, textColorActive, scrollTo }) => {
    const location = useLocation();
    const { pathname, hash } = location;
    const paramBase = location ? location.pathname.split('/')[1] : '';
    
    // Até o momento, o react-router não oferecia suporte para verificar o hash
    return (
        <TopbarMenu>                
            <TopbarMenuItem to={`${isSubdomain ? '/' + paramBase : ''}`} className={!Boolean(hash) && pathname == '/' ? 'currentPath' : null} color={textColor || '#000'} activecolor={textColorActive || '#000'}>Home</TopbarMenuItem>
            <TopbarDivider />
            <TopbarMenuItem to={`${isSubdomain ? '/' + paramBase : ''}/estoque`} className={!Boolean(hash) && pathname == '/estoque' ? 'currentPath' : null} color={textColor || '#000'}  activecolor={textColorActive || '#000'}>Estoque</TopbarMenuItem>
            <TopbarDivider />
            <TopbarMenuItem to={`${isSubdomain ? '/' + paramBase : ''}/#quem-somos`} className={hash == '#quem-somos' ? 'currentPath' : null} onClick={() => scrollTo('quem-somos')} color={textColor || '#000'}  activecolor={textColorActive || '#000'}>Quem somos</TopbarMenuItem>
            <TopbarDivider />
            <TopbarMenuItem to={`${isSubdomain ? '/' + paramBase : ''}/#unidades`} className={hash == '#unidades' ? 'currentPath' : null} onClick={() => scrollTo('unidades')} color={textColor || '#000'}  activecolor={textColorActive || '#000'}>Unidades</TopbarMenuItem>
        </TopbarMenu>
    )
}

const Topbar = ({ type = 'simple', backgroundColor, textColor, textColorActive, logo, isHome = false }) => {
    const [isSubdomain, setIsSubdomain] = useState(false);
    const [closeWrapper, setCloseWrapper] = useState();
    
    Scroll.Events.scrollEvent.register('begin', () => setCloseWrapper(true));
    Scroll.Events.scrollEvent.register('end', () => setCloseWrapper(false));

    const scrollTo = (element) => {
        setCloseWrapper(true);

        scroller.scrollTo(element, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    };

    useEffect(() => {
        if(window.location.href.includes('classi')){
            setIsSubdomain(true);
        }

        if(window.location.hash){
            scrollTo(window.location.hash.split('#')[1]);
        }
    }, []);

    return(
        <>
            <TopbarContainer 
                backgroundcolor={backgroundColor}
                simpleTopbar={Boolean(type === 'simple')}
            >
                <div className="topbar-content">
                    {type === 'with-logo' &&
                        <Hidden smDown>
                            <TopbarWithLogo
                                logo={logo}
                                options={MenuOptions({isSubdomain, textColor, textColorActive, scrollTo})}
                            />
                        </Hidden>
                    }

                    {type === 'simple' &&
                        <Hidden smDown>
                            <TopbarSimple
                                isHome={isHome}
                                logo={logo}
                                options={MenuOptions({isSubdomain, textColor, textColorActive, scrollTo})}
                            />
                        </Hidden>
                    }

                    <Hidden smUp>
                        <TopbarMobile
                            textColor={textColor}
                            logo={logo}
                            closeWrapper={closeWrapper}
                            options={MenuOptions({isSubdomain, textColor, textColorActive, scrollTo})}
                        />
                    </Hidden>
                </div>
            </TopbarContainer>
        </>
    )
};

export default Topbar;