import React from 'react';
import { UnidadesContainer, UnidadeItem, UnidadeDivider, UnidadeVerMapa } from './unidades-styled';
import { sendAnalytics } from './../../../commons/components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from './../../../commons/components/analytics-gjc/analytics-events';
import { useSelector } from 'react-redux';

// Icons
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const Unidades = ({ lista, backgroundColor, textColor }) => {
    const siteId = useSelector(state => state.template.siteId, []);

    const handleOpenMap = () => {
        sendAnalytics(analyticsEvent.VER_MAPA, { siteId: siteId });
    };

    return(
        <>
            <UnidadesContainer backgroundColor={backgroundColor}>
                {lista && lista.map(unidade => (
                    <UnidadeItem key={unidade.id} textColor={textColor}>
                        <p className="titulo">{unidade.nome}</p>
                        <UnidadeDivider color={textColor} />
                        {unidade.telefones && unidade.telefones.map(telUnidade => (
                            <p key={telUnidade.id} className="text">
                                {telUnidade.isWhatsapp && <WhatsAppIcon className="icon" />}
                                {!telUnidade.isWhatsapp && <PhoneIcon className="icon" />}
                                {telUnidade.numeroFormatado}
                            </p>
                        ))}
                        {unidade.email && 
                            <p className="text">
                                <MailOutlineIcon className="icon" /> {unidade.email}
                            </p>
                        }
                        <p className="text">{`${unidade.endereco || ''}, ${unidade.complemento || ''}, ${unidade.bairro || ''}, ${unidade.cidade || ''}-${unidade.estado || ''}`}</p>
                        {unidade.linkMapa &&
                            <UnidadeVerMapa onClick={handleOpenMap} href={unidade.linkMapa} color={textColor} target="_blank">Ver mapa</UnidadeVerMapa>
                        }
                    </UnidadeItem>
                ))}
            </UnidadesContainer>
        </>
    )
};

export default Unidades;