import { FETCH_CURRENT_USER } from './../actions/types';

const INITIAL_VALUE = {
    authentication: {
        isAuthenticated: false,
        userEmail: '',
        userName: '',
        userInfo: null
    }
};

export default (state = INITIAL_VALUE, action ) => {
    switch(action.type){
        case FETCH_CURRENT_USER:
            return { ...state, authentication: action.payload }
        default:
            return state;
    }
};