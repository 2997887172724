import React, { Component } from 'react';
import { string } from 'prop-types'

// Styles
import { Text } from './text-container-styled'
import { Container } from '../commons/theme-styled';

export default class TextContainer extends Component{
    static propTypes = {
        text: string
    }

    render(){
        return(
            <Container backgroundColor="#9b43c4" direction="row">
                <Text>{this.props.text}</Text>
            </Container>
        )
    }
};