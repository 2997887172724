import { FETCH_ANUNCIO_BY_ID } from './../actions/types';

const INITIAL_VALUE = {
    anuncio: null
}

export default (state = INITIAL_VALUE, action) => {
    const { payload } = action;

    switch(action.type) {
        case FETCH_ANUNCIO_BY_ID:
            return {
                ...state,
                anuncio: payload
            }
        default:
            return state;
    }
}