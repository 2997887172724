import React from 'react';
import ImageGallery from 'react-image-gallery';
import { GalleryContainer } from './gallery-styleds';
import ImageRender from '../image-render/image-render';

const customImageList = (imageList) => {
    return imageList.map(image => ({
        original: image.imageUrl
    }))
}

const Gallery = ({ imageList }) => {
    const customImg = (item) => {
        return(
            <ImageRender src={item.original} width={450} height={380} className="image-gallery-item" data-object-fit="cover" />
        )
    }

    return(
        <GalleryContainer>
            <ImageGallery
                items={customImageList(imageList)}
                renderItem={customImg.bind(this)}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                showBullets={true}
                disableKeyDown={true}
            />
        </GalleryContainer>
    )
};

export default Gallery;