import React, { useState, useEffect } from 'react';
import {
  FilterContainer,
  FilterSelect,
  FilterBtn,
  FilterToggleBtn,
} from './filter-styled';
import { InputLabel, Select, MenuItem, Hidden } from '@material-ui/core';
import FilterForm from './filterForm';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBusca,
  consultarMarcasByCategoria,
  consultarModelosByMarca,
  consultaEstados,
} from '../../../../actions';
import { Formik, useFormikContext } from 'formik';
import { FETCH_SEARCH_OBJECT } from '../../../../actions/types';

// TODO: Remover categorias fixa
const defaultFilter = {
  categorias: '165',
  marca: null,
  modelo: null,
  estado: null,
  pagina: 0,
  paginado: true,
  qtdRegistros: 12,
  secao: 'VEICULOS',
};

const Filter = ({ type, backgroundColor }) => {
  const dispatch = useDispatch();
  const [openWrapper, setOpenWrapper] = useState(false);
  const [oldSearchObj, setOldSearchObj] = useState();

  const searchObj = useSelector((state) => state.busca.searchObj, []);
  const filtros = useSelector((state) => state.busca.filtros, []);
  const marcas = useSelector((state) => state.busca.marcas, []);
  const modelos = useSelector((state) => state.busca.modelos, []);
  const estados = useSelector((state) => state.busca.estados, []);

  useEffect(() => {
    dispatch(fetchBusca({ filter: defaultFilter }));
    dispatch({
      type: FETCH_SEARCH_OBJECT,
      payload: defaultFilter,
    });
    setOldSearchObj(defaultFilter);
  }, []);

  useEffect(() => {
    dispatch(consultarMarcasByCategoria(165));
  }, []);

  useEffect(() => {
    dispatch(consultaEstados());
  }, []);

  useEffect(() => {
    if (searchObj && searchObj !== oldSearchObj) {
      dispatch(fetchBusca({ filter: searchObj }));
      setOldSearchObj(searchObj);
    }
  }, [searchObj]);

  return (
    <>
      {filtros && (
        <FilterContainer
          backgroundColor={backgroundColor}
          compact={Boolean(type === 'compact').toString()}
        >
          <Hidden smUp>
            <FilterToggleBtn
              startIcon={<FilterListIcon />}
              onClick={() => setOpenWrapper(!openWrapper)}
            >
              Filtrar
            </FilterToggleBtn>
          </Hidden>

          <Formik
            initialValues={defaultFilter}
            enableReinitialize
            onSubmit={(values) => {
              let newValues = Object.assign(searchObj, values);
              newValues.pagina = 0;

              dispatch({
                type: FETCH_SEARCH_OBJECT,
                payload: { ...searchObj, newValues },
              });
            }}
          >
            {({ handleSubmit }) => (
              <FilterForm
                handleSubmit={handleSubmit}
                type={type}
                marcas={marcas}
                modelos={modelos}
                estados={estados}
                filtros={filtros}
                openWrapper={openWrapper}
              />
            )}
          </Formik>
        </FilterContainer>
      )}
    </>
  );
};

export default Filter;
