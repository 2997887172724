import React, { useEffect, useState } from 'react';
import { fetchGaragemLayout } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getParams, getClientSiteName } from '../../../resources/helpers/getParams';
import { ProtectLayer } from './Home-styled';
import { Helmet } from 'react-helmet';
import { initAnalytics, enviarPageView } from './../../commons/components/analytics-gjc/analytics-gjc';

// Templates
import CardsTemplate from '../templates/Cards-template';
import TikectsTemplate from '../templates/Ticket-template';
import ListaTemplate from '../templates/Lista-template';

import { buildUrl } from '../components/image-render/image-render';

const HomeGaragem = () => {
    const [protect, setProtect] = useState(false);

    const dispatch = useDispatch();
    const siteId = useSelector(state => state.template.siteId, []);
    const layout = useSelector(state => state.template.layout, []);
    const institucional = useSelector(state => state.template.institucional, []);
    const unidades = useSelector(state => state.template.unidades, []);

    useEffect(() => {
        if(layout && !layout.template){
            const urlParams = getParams(window.location.href);         
            const name = getClientSiteName(window.location.pathname);

            if(urlParams.params){
                setProtect(true);
            }

            dispatch(fetchGaragemLayout({hostName: window.location.hostname, nome: name, params: urlParams.params}));

        }
    }, []);

    useEffect(() => {
        
        if(siteId){
            initAnalytics(`${siteId}_home`, siteId);
            enviarPageView();
        }

    }, [siteId]);

    const getFavIcon = (size, logoUrl) => {
        return buildUrl({src: logoUrl, width: size, height: size, resizeType: 'fit'});
    }

    return(
        <>
            {layout &&
                <Helmet>
                    <link rel="icon" type="image/png" href={getFavIcon('32', layout.logo)} />
                    <link rel="apple-touch-icon" sizes="76x76" href={getFavIcon('76', layout.logo)} />
                </Helmet>
            }

            {protect &&
                <ProtectLayer />
            }
            {layout && layout.template && layout.template.toLowerCase() === 'card' &&
                <CardsTemplate layout={layout} institucional={institucional} unidades={unidades} />
            }
            {layout && layout.template && layout.template.toLowerCase() === 'ticket' &&
                <TikectsTemplate layout={layout} institucional={institucional} unidades={unidades} />
            }
            {layout && layout.template && layout.template.toLowerCase() === 'lista' &&
                <ListaTemplate 
                    layout={layout} 
                    institucional={institucional} 
                    unidades={unidades} 
                />
            }
        </>
    );
};

export const loadData = ({store, match, req}) => {
    const fullUrl = req.get('host');
    const nome = req.originalUrl.split('/')[1] || null;

    return store.dispatch(fetchGaragemLayout({hostName: fullUrl, nome: nome}));
};

export default HomeGaragem;