import Axios from 'axios';
import Cookies from 'js-cookies';

export const getAnunciosGenerico = (filtro) => {
    const { texto, pagina, qntRegistros, paginado, inicioPaginacao} = filtro;
    let anuncios = [], resultados = 0;
    const auth_token = Cookies.getItem('auth_token');

    return new Promise((resolve, reject) => {
        Axios({
            url: `${process.env.API_URL}/anuncio-digital/searchAnuncioDigitalGenerico`,
            method: 'post',
            headers: { 
                'Authorization': auth_token ? `Bearer ${auth_token}`  : 0
            },
            data: {
                'texto': texto,
                'pagina': pagina,
                'qntRegistros': qntRegistros,
                'paginado': paginado,
                'inicioPaginacao': inicioPaginacao
            }
        }).then((response) => {
            anuncios   = response.data.anuncios;
            resultados = response.data.qtTotalRegistros;
    
            resolve({ anuncios, resultados });
        });
    })
}

export const getLojas = () => {

    return new Promise((resolve, reject) => {
        // Erro cross-origin
        Axios.get(`${process.env.API_URL}/lojas-classificados`).then((response) => {
            let lojasObj = {}, lojasArray = [];
            
            lojasObj = response.data;

            //Retorna enderecos de GoiÃ¢nia
            Object.keys(lojasObj.goiania).map( bairro => {
                lojasObj.goiania[bairro].map( endereco => {
                    endereco.bairro = bairro;
                    lojasArray.push(endereco);
                });
            });

            //Retorna enderecos de Outros bairros
            Object.keys(lojasObj.outrasCidades).map( bairro => {
                lojasObj.outrasCidades[bairro].map( endereco => {
                    endereco.bairro = bairro;
                    lojasArray.push(endereco);
                });
            });

            const autorizadas = lojasArray;

            resolve({ autorizadas });
        });
    });

}

export const addFavorito = (idAnuncio) => {
    const auth_token = Cookies.getItem('auth_token');

    return new Promise((resolve, reject) => {
        Axios({
            url: `${process.env.API_URL}/favorito/favoritarAnuncio`,
            method: 'get',
            headers: { 'Authorization': auth_token ? `Bearer ${auth_token}`  : 0 },
            params: {
                "idAnuncio": parseFloat(idAnuncio)
            }
        }).then((response) => {
            resolve({isFavorite: true});
        })
    });
}

export const removeFavorito = (idAnuncio) => {
    const auth_token = Cookies.getItem('auth_token');

    return new Promise((resolve, reject) => {
        Axios({
            url: `${process.env.API_URL}/favorito//desfavoritarAnuncio`,
            method: 'get',
            headers: { 'Authorization': auth_token ? `Bearer ${auth_token}`  : 0 },
            params: {
                "idAnuncio": parseFloat(idAnuncio)
            }
        }).then((response) => {
            resolve({isFavorite: false});
        })
    })

}