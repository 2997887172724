import React from 'react';
import { RedesSociaisContainer, RedeIcon } from './redesSociais-styled';

// Icons
import FacebookIcon from './../../../../resources/svg/facebook-logo-white.svg';
import InstagramIcon from './../../../../resources/svg/instagram-white.svg';

const RedesSociais = ({ redes }) => {
    const getIconByType = (type) => {
        switch(type){
            case 'facebook':
                return FacebookIcon;
            case 'instagram':
                return InstagramIcon;
        }
    }

    return (
        <RedesSociaisContainer>
            {redes.map(rede => (
                <RedeIcon src={getIconByType(rede.tipo.toLowerCase())} href={rede.urlPerfil} className={rede.tipo.toLowerCase()}/>
            ))}
        </RedesSociaisContainer>
    )
};

export default RedesSociais;