import React from 'react';
import { TopbarMenu, TopbarMenuItem, TopbarDivider, TopbarSimpleContainer, TopbarLogo } from './topbar-styled';

const TopbarSimple = ({options, logo, isHome}) => {
    return(
        <>
            {!isHome &&
                <TopbarLogo src={logo} to="/"/>
            }
            <TopbarSimpleContainer>
                {options}
            </TopbarSimpleContainer>
        </>
    )
};

export default  TopbarSimple;