import styled from 'styled-components';

export const FavContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    .fav-icon{
        width: 28px;
        height: 25px;

        position: absolute;
        top: 10px;
        right: 7px;

        path:last-child{
            fill: ${ props => props.favorite  };
            stroke: ${ props => props.favorite == '#e45353' ? '#e45353' : 'gray' };
            cursor: pointer;

            &:hover{
                fill: #e45353;
                stroke: #e45353;
            }
        }
    }
    
    &:after{
        content: "";
        border-top: 65px solid #fff;
        border-left: 65px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

`;