import React from "react";
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import ImageRender from "../image-render/image-render";

const colorObj = {
    IMOVEL_VENDA: '#007dc5',
    IMOVEL_ALUGUEL: '#00bcd4',
    VEICULOS: '#cf2429',
    MOTOS: '#940057',
    AGRONEGOCIO: '#0b9140',
    NEGOCIOS: '#ffa000',
    EMPREGOS: '#f07226'
};

export const LinkContainer = styled(Link)`
    &&{
        width: calc(100% - 250px);
        height: 200px;
        max-width: 1024px;

        margin-bottom: 20px;

        @media screen and (max-width: 768px){
            width: calc(100% - 150px);
            height: 150px;
        }  
    }
`;

export const CardContainer = styled(Paper)`
    width: 100%;
    height: 200px;
    max-width: 1024px;

    display: flex;
    flex-direction: row;

    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        height: 150px;
    }  
`;

export const Image = styled.img`
    width: 250px;
    height: 200px;
    object-fit: cover;

    background: url('${ props => props.backgroundImg ? props.backgroundImg : null}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-color: ${props => colorObj[props.vitrine]};

    @media screen and (max-width: 768px){
        width: 150px;
        height: 150px;
    }  
`;

export const ImgContainer = styled.div`
    position: relative;
`;

export const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    border-top: 2px solid ${props => colorObj[props.vitrine]};
    
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardImage = styled(props => (
    <Link to={{ pathname: props.url }} {...props}>
        <ImageRender src={props.src} width="250" height="200" className="image" />
        {props.mask && <img className="mask" src={props.mask} />}
    </Link>
))`
    && {
        width: 100%;
        height: 100%;

        .image {
            width: 250px;
            height: 200px;
            object-fit: cover;

            background: url('${ props => props.backgroundImg ? props.backgroundImg : null}');
            background-position: 50%, 57%;
            background-repeat: no-repeat;
            background-size: 90%, auto 100%;
            background-color: ${props => colorObj[props.vitrine]};
        }

        .mask{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: bottom;

            position: absolute;
            top: 0;
            left: 0;
        }
    }
`;

export const Icone = styled.img`
    width: 25px;
    height: 25px;

    margin-right: 5px;
`;

export const InfoVitrine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TituloVitrine = styled.p`
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    color: ${props => colorObj[props.vitrine]};

    margin-top: 0;
    margin-bottom: 0;
`;

export const InfoAnuncio = styled.div`
    margin-bottom: auto;
`;

export const TituloAnuncio = styled.p`
    font-size: 24px;
    font-weight: 500;
    color: #394053;

    margin-top: 5px;
    margin-bottom: 5px;

    height: 30px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    max-width: 100%;
    overflow: hidden;

    @media screen and (max-width: 768px){
        max-height: 60px;
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const DescricaoAnuncio = styled.p`
    font-size: 18px;
    font-weight: 500;
    color: #626463;

    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const DetalhesContainer = styled.div`
    width: calc(100% - 300px);
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const DetalheInfo = styled.div`
    width: 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 5px;
`;

export const DetalheValue = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #626463;
    margin-top: 0;
    margin-bottom: 0;
`;

export const DetalheLabel = styled.p`
    font-size: 16px;
    color: #626463;
    margin-top: 0;
    margin-bottom: 0;
`;

export const FooterAnuncio = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    margin-top: 5px;

    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`;

export const Localizacao = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #626463;

    margin-top: 0;
    margin-bottom: 0;
`;

export const Valor = styled.p`
    font-size: 18px;
    font-weight: 500;
    color: ${props => colorObj[props.vitrine]};

    margin-top: 0;
    margin-bottom: 0;
`;