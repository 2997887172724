import { combineReducers } from 'redux';
import authReducer from './authReducer';
import garagemReducer from './garagemReducer';
import buscaReducer from './buscaReducer';
import anuncioReducer from './anuncioReducer';
import contatoReducer from './contatoReducer';

export default combineReducers({
    anuncio: anuncioReducer,
    contato: contatoReducer,
    authentication: authReducer,
    template: garagemReducer,
    busca: buscaReducer
})