import React, { useState } from 'react';
import { HamburguerContainer, StripeTop, StripeMiddle, StripeBottom } from './hamburguer-styled';

const Hamburguer = ({ textColor, onClick, open }) => {

    return (
        <HamburguerContainer textColor={textColor} onClick={ onClick }>
            <StripeTop className="stripe" open={ open }></StripeTop>
            <StripeMiddle className="stripe" open={ open }></StripeMiddle>
            <StripeBottom className="stripe" open={ open }></StripeBottom>
        </HamburguerContainer>
    );

};

export default Hamburguer;