import styled from 'styled-components';
import React from 'react';

const BannerWithInfo = `
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const BannerContainer = styled.div`
    width: 100%;
    height: auto;

    line-height: 0;

    ${props => props.withInfo ? BannerWithInfo : null }
`;

export const BannerImage = styled.img`
    width: 100%;
`;

export const BannerLogo = styled(props => (
    <div {...props}>
        <img src={props.src} />
    </div>
))`
    width: 250px;
    height: 250px;

    background-color: #000;
    border-radius: 50%;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    img{
        max-width: 100%;
        max-height: 100%;
    }
`;

export const BannerSmallImage = styled(props => (
    <div {...props}>
        <img src={props.src} />
    </div>
))`
    width: 350px;
    height: 250px;
    overflow: hidden;

    img{
        width: auto;
        min-height: 100%;
        max-height: 100%;
    }

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const BannerText = styled.div`
    width: 250px;

    p{
        font-size: 21px;
        color: #666;

        line-height: 31px;

        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const BannerWithInfoContainer = styled.div`
    width: 100%;
    max-width: 900px;

    margin-top: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
`;