import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Topbar from './../components/topbar/topbar';
import Banner from './../components/banner/banner';
import Filter from './../components/filter/filter';
import QuemSomos from './../components/quem-somos/quem-somos';
import Unidades from '../components/unidades/unidades';
import Copyright from '../components/copyright/copyright';
import Card from '../components/cards/card';
import { CardContainer, LoadMoreBtn } from '../components/cards/card-styled';
import { fetchBusca } from '../../../actions';
import { FETCH_SEARCH_OBJECT } from '../../../actions/types';
import { Element } from 'react-scroll';
import RedesSociais from '../components/redes-sociais/redesSociais';

const TikectsTemplate = ({ layout, institucional, unidades }) => {
    const dispatch = useDispatch();

    const anuncios = useSelector(state => state.busca.anuncios);
    const searchObj = useSelector(state => state.busca.searchObj);
    const filtros = useSelector(state => state.busca.filtros);

    const loadMoreAnuncios = () => {
        if(filtros.qtdPaginas > filtros.paginaAtual){
            dispatch({
                type: FETCH_SEARCH_OBJECT,
                payload: {...searchObj, pagina: filtros.paginaAtual + 1}
            });
        }
    }

    return (
        <>
            <Topbar 
                type={'with-logo'}
                backgroundColor={layout.cor_principal}
                textColor={layout.cor_texto}
                textColorActive={layout.cor_texto_ativo}
                logo={layout.logo}
                bannerDesktop={layout.banner_desktop}
                bannerMobile={layout.banner_mobile}
            />
            <Banner 
                type='fullWidth'
                desktopImg={layout.banner_desktop}
                mobileImg={layout.banner_mobile}
            />
            <Filter backgroundColor={layout.cor_principal}/>
            <CardContainer>
                {anuncios && anuncios.map(anuncio => (
                    <Card type='card-vertical' defaultImg={layout.logo} defaultColor={layout.cor_principal} info={anuncio} />
                ))}

                {filtros && filtros.qtdPaginas > filtros.paginaAtual + 1 &&
                    <LoadMoreBtn textcolor={layout.cor_texto_botao} backgroundcolor={layout.cor_secundaria} variant="outlined" onClick={loadMoreAnuncios}>Carregar mais</LoadMoreBtn>
                }
            </CardContainer>

            <Element name="quem-somos"/> 
            <QuemSomos
                lineColor={layout.cor_secundaria}
                imagem={institucional.imagem}
                titulo={institucional.titulo}
                texto={institucional.texto}
            />

            {layout.redesSociais &&
                <RedesSociais redes={layout.redesSociais} />
            }

            {unidades &&
                <>
                    <Element name="unidades"/> 
                    <Unidades
                        backgroundColor={layout.cor_principal}
                        textColor={layout.cor_texto}
                        lista={unidades}
                    />
                </>
            }
            <Copyright 
                backgroundColor={layout.cor_principal}
                textColor={layout.cor_texto}
            />
        </>
    )
}

export default TikectsTemplate;