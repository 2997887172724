import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles/colorManipulator';

export const MaterialForm = styled.div`
    width: 720px;
    height: 270px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;

    border-radius: 20px;

    @media screen and (max-width: 720px){
        width: 100%;
        height: initial;
        flex-direction: column;
        border-radius: 0;
    }
`;

export const ExpandButton = styled(Button)`
    &&{
        background-color: #9b43c4;
        padding: 20px 70px;

        z-index: 3;

        transform: scale(1);
        transition-delay: 2s;
        transition: transform 500ms cubic-bezier(0.4, 0.0, 0.2, 1), opacity 600ms cubic-bezier(0.4, 0.0, 0.2, 1);
        transition-delay: 350ms;

        span{
            transition: opacity 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
            transition-delay: 350ms;
        }

        &:hover{
            background: ${darken('#9b43c4', 0.1)};
        }

        &.open{
            transform: scale(5);
            z-index: 0;

            span{
                opacity: 0;
            }
        }

        @media screen and (max-width: 720px){
            position: relative;
            display: none;
        }
    }
`;

export const ExpandContent = styled.div`
    width: 100%;
    height: 100%;
    background-color: #FFF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin-left: -50%;
    margin-right: -50%;

    z-index: 2;

    overflow: hidden;

    transition: opacity 800ms cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-delay: 600ms;
    opacity: ${ props => props.showcontent == '1' ? '1' : '0'};

    border-radius: 20px;
    border: 5px solid #9b43c4;

    @media screen and (max-width: 720px){
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        border-radius: 0;
        border: none;
    }
`;

export const ContentHeader = styled.div`
    width: 100%;
    padding: 20px;
    background-color: #9b43c4;
`;

export const Titulo = styled.p`
    font-size: 21px;
    font-weight: bold;
    color: #FFF;

    text-align: center;

    margin-top: 0;
    margin-bottom: 0;
`;

export const Subtitulo = styled.p`
    font-size: 18px;
    font-weight: 600;
    color: #9b43c4;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 10px;
`;