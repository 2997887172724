import React from 'react';

const imgproxy = process.env.IMG_PROXY;

export const buildUrl = ({ src, width, height, resizeType='fit', gravity='ce', enlarge='0' }) => {
    return `${imgproxy}/${resizeType}/${width}/${height}/${gravity}/${enlarge}/plain/${src}`;
};

const ImageRender = ({src, width, height, resizeType, gravity, enlarge, ...props}) => {

    return (
        <img alt="" src={`${imgproxy}/${resizeType}/${width}/${height}/${gravity}/${enlarge}/plain/${src}`} width={`${width}`} heigth={`${height}`} {...props} />
    ) ; 

};

ImageRender.defaultProps = {
    resizeType: 'auto',
    gravity: 'ce',
    enlarge: '0'
}

export default ImageRender;