import React, { Component } from 'react';
import { string, number, object, bool } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CurrencyFormat from 'react-currency-format';
import Favoritar from '../favoritar/favoritar';
import Cookies from 'js-cookies';

// Styles
import {
    CardContainer,
    DescricaoAnuncio,
    DetalhesContainer,
    DetalheInfo,
    DetalheValue,
    DetalheLabel,
    ImgContainer,
    CardImage,
    FooterAnuncio,
    Localizacao,
    LinkContainer,
    InfoAnuncio,
    InfoContainer,
    InfoVitrine,
    Icone,
    TituloAnuncio,
    TituloVitrine,
    Valor
} from './card-anuncio-styled';

// Images
import ImovelVendaSVG from './../../../../resources/svg/vitrines/logo-imovel-venda.svg';
import ImovelAluguelSVG from './../../../../resources/svg/vitrines/logo-imovel-aluguel.svg';
import CarrosSVG from './../../../../resources/svg/vitrines/logo-carros.svg';
import MotosSVG from './../../../../resources/svg/vitrines/logo-motos.svg';
import AgroSVG from './../../../../resources/svg/vitrines/logo-agro.svg';
import NegociosSVG from './../../../../resources/svg/vitrines/logo-negocios.svg';
import EmpregosSVG from './../../../..//resources/svg/vitrines/logo-empregos.svg';

import ImovelVendaSVG_White from './../../../../resources/svg/vitrines/white/imovel-venda-white.svg';
import ImovelAluguelSVG_White from './../../../../resources/svg/vitrines/white/imovel-aluguel-white.svg';
import CarrosSVG_White from './../../../../resources/svg/vitrines/white/carros-white.svg';
import MotosSVG_White from './../../../../resources/svg/vitrines/white/motos-white.svg';
import AgroSVG_White from './../../../../resources/svg/vitrines/white/agro-white.svg';
import NegociosSVG_White from './../../../../resources/svg/vitrines/white/negocios-white.svg';
import EmpregosSVG_White from './../../../../resources/svg/vitrines/white/empregos-white.svg';
import { addFavorito, removeFavorito } from './../../../../api/api';

export default class CardAnuncio extends Component {
    state = { depth: 2 }

    static propTypes = {
        vitrine: string,
        vitrineNome: string,
        titulo: string,
        descricao: string,
        localizacao: string,
        valor: number,
        thumbnail: string,
        url: string,
        detalhes: object,
        isFavorite: bool,
        imageMask: string
    };

    componentDidMount() {
        this.setState({
            isFavorite: this.props.isFavorite,
            isAuthenticated: Cookies.getItem('auth_token') ? true : false
        });
    }

    onMouseOver = () => this.setState({ depth: 4 });
    onMouseOut = () => this.setState({ depth: 2 });

    getDefaultImg = () => {
        switch (this.props.vitrine) {
            case 'IMOVEL_VENDA':
                return { color: ImovelVendaSVG, white: ImovelVendaSVG_White };
            case 'IMOVEL_ALUGUEL':
                return { color: ImovelAluguelSVG, white: ImovelAluguelSVG_White };
            case 'VEICULOS':
                return { color: CarrosSVG, white: CarrosSVG_White };
            case 'MOTOS':
                return { color: MotosSVG, white: MotosSVG_White };
            case 'AGRONEGOCIO':
                return { color: AgroSVG, white: AgroSVG_White };
            case 'NEGOCIOS':
                return { color: NegociosSVG, white: NegociosSVG_White };
            case 'EMPREGOS':
                return { color: EmpregosSVG, white: EmpregosSVG_White };
            default:
                return NegociosSVG;
        }
    }

    getDetalhes = () => {
        let infos = [], label = [];

        switch (this.props.vitrine) {
            case 'IMOVEL_VENDA':
                infos.push(this.props.detalhes.areaUtil ? this.props.detalhes.areaUtil : '-');
                infos.push(this.props.detalhes.qtdeQuartos ? this.props.detalhes.qtdeQuartos : '-');
                infos.push(this.props.detalhes.qtdeVagas ? this.props.detalhes.qtdeVagas : '-');
                label.push('Área útil');
                label.push('Quartos');
                label.push('Vagas');
            case 'VEICULOS':
                infos.push(this.props.detalhes.anoFabricacao ? this.props.detalhes.anoFabricacao.toString().split('').splice(-2).join('') + '/' + this.props.detalhes.anoVersao.toString().split('').splice(-2).join('') : '-');
                infos.push(this.props.detalhes.quilometragem ? this.props.detalhes.quilometragem : '-');
                infos.push(this.props.detalhes.tipoCombustivel ? this.props.detalhes.tipoCombustivel.descricao : '-');
                label.push('Ano');
                label.push('Km');
                label.push('Combustível');
            case 'MOTOS':
                infos.push(this.props.detalhes.anoFabricacao ? this.props.detalhes.anoFabricacao : '-');
                infos.push(this.props.detalhes.quilometragem ? this.props.detalhes.quilometragem : '-');
                infos.push(this.props.detalhes.tipoCombustivel ? this.props.detalhes.tipoCombustivel.descricao : '-');
                label.push('Ano');
                label.push('Km');
                label.push('Combustível');
            default:
                break;
        }

        if (infos.length > 0) {
            return <DetalhesContainer>
                <DetalheInfo>
                    <DetalheValue>{infos[0]}</DetalheValue>
                    <DetalheLabel>{label[0]}</DetalheLabel>
                </DetalheInfo>
                <DetalheInfo>
                    <DetalheValue>{infos[1]}</DetalheValue>
                    <DetalheLabel>{label[1]}</DetalheLabel>
                </DetalheInfo>
                <DetalheInfo>
                    <DetalheValue>{infos[2]}</DetalheValue>
                    <DetalheLabel>{label[2]}</DetalheLabel>
                </DetalheInfo>
            </DetalhesContainer>
        } else {
            return <div></div>
        }
    }

    toggleFavorito = () => {
        if (this.state.isFavorite && this.state.isAuthenticated) {
            removeFavorito(this.props.anuncioId).then((response) => {
                this.setState({ isFavorite: response.isFavorite });
            });
        } else if (!this.state.isFavorite && this.state.isAuthenticated) {
            addFavorito(this.props.anuncioId).then((response) => {
                this.setState({ isFavorite: response.isFavorite });
            });
        }

    }

    render() {
        const defaultImg = this.getDefaultImg();

        return (
            <CardContainer elevation={this.state.depth} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>
                <ImgContainer>

                    <CardImage
                        href={this.props.url}
                        target="_blank"
                        src={this.props.thumbnail}
                        mask={this.props.imageMask} 
                        vitrine='white' />

                    <Favoritar isFavorite={this.state.isFavorite} callback={this.toggleFavorito}></Favoritar>
                </ImgContainer>

                <LinkContainer href={this.props.url} target="_blank">
                    <InfoContainer vitrine={this.props.vitrine}>
                        <InfoVitrine>
                            <Icone src={defaultImg.color} />
                            <TituloVitrine vitrine={this.props.vitrine}>{this.props.vitrineNome}</TituloVitrine>
                        </InfoVitrine>

                        <InfoAnuncio>
                            <TituloAnuncio>{this.props.titulo}</TituloAnuncio>
                            <DescricaoAnuncio dangerouslySetInnerHTML={{ __html: this.props.descricao }} />
                        </InfoAnuncio>

                        <FooterAnuncio>
                            <Localizacao>{this.props.localizacao}</Localizacao>
                            {this.getDetalhes()}
                            <Valor vitrine={this.props.vitrine}>
                                {
                                    this.props.valor ?
                                        <CurrencyFormat value={this.props.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        : 'VER ANÚNCIO'
                                }
                            </Valor>
                        </FooterAnuncio>
                    </InfoContainer>
                </LinkContainer>

            </CardContainer>
        )
    }

}