import styled from 'styled-components';

export const AnuncioContainer = styled.div`
    width: 100%;
    
    display: flex;
    flex-direction: column;
`;

export const AnuncioTitleContainer = styled.div`
    width: 100%;

    padding: 30px;

    display: flex;
    flex-direction: column;

    p{ margin-top: 0; margin-bottom: 0; }

    .title {
        font-size: 26px;
        font-weight: bold;

        margin-bottom: 10px;
    }

    .subtitle {
        color: #333333;
    }

    .price {
        font-size: 42px;
        font-weight: bold;

        margin-top: 20px;
        margin-bottom: 10px;
    }

    .codigo {
        font-size: 18px;
        color: #333333;
    }

    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0 10px;
        margin-top: 20px;
    }
`;

export const AnuncioResumo = styled.div`
    width: 100%;
    background-color: #eaeaea;
    padding: 30px;

    display: flex;

    @media screen and (max-width: 768px){
        padding: 0;
    }
`;

export const AnuncioInfo = styled.ul`
    width: 100%;

    padding: 0;
    column-count: 3;
    list-style: none;

    li{
        margin-bottom: 20px;
    }

    p{ 
        margin-top: 0;
        margin-bottom: 0;
    }

    .title{
        font-weight: bold;
    }

    .value{
        color: #8b8b8b;
    }

    @media screen and (max-width: 768px){
        width: 100%;
        padding: 0 10px;
    }
`;

export const ResumoContainer = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-left: auto;
    margin-right: auto;

    .left-side{
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .right-side{
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: 768px){
        flex-direction: column;

        .left-side, .right-side {
            width: 100%;
        }
    }
`;

export const AnuncioSection = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: column;

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px){
        padding: 0 10px;
    }
`;

export const SectionTitle = styled.p`
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    border-bottom: 1px solid #828282;
    margin-top: 30px;
    margin-bottom: 20px;
`;

export const SectionText = styled.p`
    font-size: 18px;
    color: #8b8b8b;
    line-height: 1.8;
    margin-top: 0;
    margin-bottom: 0;
`;

export const SectionList = styled.ul`
    width: 100%;
    color: #8b8b8b;

    column-count: 3;
    padding-left: 20px;

    margin-top: 0;
    margin-bottom: 0;

    li {
        font-size: 18px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px){
        column-count: 1;
    }
`;