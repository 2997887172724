import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from "react-redux";
import reducers from "./../reducers";
import thunk from "redux-thunk";
import Keycloak from 'keycloak-js';
import { FETCH_CURRENT_USER } from "../actions/types";

// Routes
import Routes from './routes';
import RoutesGaragem from './routes-garagem';

const history = createBrowserHistory();

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, window.INITIAL_STATE, composeEnhancers(applyMiddleware(thunk)));

if(window.location.href.includes('classi') && !window.location.href.includes('template')){
    window.keycloak = Keycloak({
        'realm': 'accounts',
        'url': `${process.env.ACCOUNTS_URL}`,
        'ssl-required': 'external',
        'clientId': 'classi-capa',
        'public-client': true,
        'confidential-port': 0
    });
    
    window.keycloak.init({ onLoad: 'check-sso' }).success((authenticated) => {
        if (authenticated) {
            let keycloakData = {};
            keycloakData.isAuthenticated = authenticated;
            keycloakData.userEmail = window.keycloak.idTokenParsed.email;
            keycloakData.userName = window.keycloak.idTokenParsed.given_name;
    
            store.dispatch({
                type: FETCH_CURRENT_USER,
                payload: keycloakData
            });
    
            setInterval(() => {
                window.keycloak.updateToken(10).error(() => {
                    console.log('LOGOUT');
                    window.keycloak.logout();
                });
            }, 10000);
        }
    }).error((e) => {
        console.log('failed to initialize');
    });
}

// Evento para fechar a intervençao
var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
eventer(messageEvent, function (e) {
    if (e.data === 'close-intervencao' || e.message === 'close-intervencao') {
        var intervencaoHolders = document.getElementsByClassName('intervencao-holder');
        for (var i = 0; i < intervencaoHolders.length; i++) {
            intervencaoHolders[i].style.display = 'none';
        }
    }
});

const Main = () => {
    const [routes, setRoutes] = React.useState();

    React.useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side-material');
        const hostname = window.location.host;

        if(jssStyles){
            jssStyles.parentElement.removeChild(jssStyles);
        }

        if(hostname.includes('classi')){
            setRoutes(Routes);
        } else {
            setRoutes(RoutesGaragem);
        }
    }, []);

    return(
        <>
            <Provider store={store}>        
                <Router history={history}>
                    {renderRoutes(routes)}
                </Router>
            </Provider>
        </>
    )
}

ReactDOM.hydrate(<Main />, document.querySelector("#root"));