import React, { Component } from 'react';

//Style
import { Image } from './plano-container-styled';
import PlanosImg from './../../../../resources/imgs/bg-planos.png';
import { AlignCenter, Container, Titulo, SimpleText, PurpleButton, TextButton } from '../commons/theme-styled';

export default class PlanoContainer extends Component{
    render(){

        return(
            <Container id="planos" direction="row" backgroundColor="#d4ffff">
                <AlignCenter>
                    <Titulo>
                        Revendedores tem acesso a
                        <br />
                        plataforma e planos exclusivos
                    </Titulo>
                    
                    <SimpleText>
                        Proprietário de imobiliária, garagem de carros/motos ou comércio de 
                        <br />
                        produtos do agronegócio tem acesso a uma plataforma exclusiva para 
                        <br />
                        facilitar sua vida, e planos de acorco com a sua demanda de anúncios.                           
                    </SimpleText>

                    <PurpleButton href={process.env.URL_PLANOS}>Acesse seu plano</PurpleButton>
                    <br />
                    <TextButton href={process.env.URL_CADASTRAR_PLANO}>ou cadastre-se</TextButton>
                </AlignCenter>

                <Image src={PlanosImg} />         
            </Container>
        )

    }
}