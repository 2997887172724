import styled from 'styled-components';
import React from 'react';
import { NavLink } from 'react-router-dom';

export const RedesSociaisContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-bottom: 30px;
`;

export const RedeIcon = styled(props => (
    <a href={props.href} target="_blank">
        <div {...props}>
            <img src={props.src} />
        </div>
    </a>
))`
    width: 65px;
    height: 65px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    margin-left: 10px;
    margin-right: 10px;

    img{
        width: 60%;
    }

    &.facebook{
        background-color: #3b5998;
    }

    &.instagram {
        background-color: #ee539e;
    }
`;