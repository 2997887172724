import styled from 'styled-components';

export const CopyrightContainer = styled.div`
    width: 100%;
    padding: 10px;

    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#FFF' };

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p{ 
        font-size: 12px;
        color: ${props => props.textColor ? props.textColor : '#000' };
        margin-top: 0;
        margin-bottom: 0;
    }
`;