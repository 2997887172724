import React from 'react';

import App from './App';

import HomeGaragem, { loadData as GaragemLoadData } from './../modules/garagens/pages/Home';
import Estoque, { loadData as EstoqueLoadData } from './../modules/garagens/pages/Estoque';
import Anuncio, { loadData as AnuncioLoadData } from './../modules/garagens/pages/Anuncio';
import Error404 from '../modules/errors/Error404';

const RoutesGaragem = [
    {
        component: App,
        routes: [
            {
                component: HomeGaragem,
                loadData: GaragemLoadData,
                path: '/',
                exact: true
            },
            {
                component: Estoque,
                loadData: EstoqueLoadData,
                path: '/estoque',
                exact: true
            },            
            {
                component: Error404,
                path: '/404',
                exact: true
            },
            {
                component: Anuncio,
                loadData: AnuncioLoadData,
                path: '/anuncio/:anuncio',
                exact: true
            },
            {
                component: HomeGaragem,
                path: '/template'
            },
            {
                component: Error404,
            },
        ]
    }
]

export default RoutesGaragem;