import React, { Component }  from 'react';
import { Container } from '../commons/theme-styled';
import { number, string } from 'prop-types';

//Style
import { Titulo, Divisor, Label } from './busca-divisor-styled';

export default class BuscaDivisor extends Component{
    static propTypes = {
        resultados: number,
        titulo: string
    }

    static defaultProps = {
        resultados: 0,
        titulo: ''
    }

    render() {
        return(
            <Container>
                <Divisor>
                    <Label>
                        Foram encontrados {this.props.resultados} resultados para: 
                    </Label>
                    <Titulo>{this.props.titulo}</Titulo>
                </Divisor>
            </Container>
        )
    }

}