import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

// TODO: Criar objeto com as cores

// Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { darken } from '@material-ui/core/styles/colorManipulator';

// Icons
import MailOutline from '@material-ui/icons/MailOutline';
import Facebook from './../../../../resources/svg/facebook-logo.svg';
import Twitter from './../../../../resources/svg/twitter.svg';

// Components personalizados
export const Container = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    padding: ${props => props.noPadding ? null : '30px 10px'};

    background-image:  url(${props => props.backgroundImg ? props.backgroundImg : null});
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'unset'};
    background-repeat: no-repeat;
    background-size: cover;
    Background-position: 0 100%;

    margin-top: ${props => props.firstContainer ? '40px' : null};
    padding-top: ${props => props.firstContainer ? '80px' : null};

    display:  ${props => props.onlyMobile ? 'none' : 'flex'};
    flex-wrap: wrap;

    flex-direction: ${props => props.direction ? props.direction : 'row'};
    align-items: ${props => props.align ? props.align : 'center'};
    justify-content: ${props => props.justify ? props.justify : 'center'};

    @media screen and (max-width: 768px){
        padding-top: ${props => props.paddingTopOnBreak ? props.paddingTopOnBreak : (props => props.firstContainer ? '80px' : null)};
        display: ${props => props.noMobile ? 'none' : 'flex'};
        align-items: ${props => props.mobileAlign ? props.mobileAlign : ( props.align ? props.align : 'center' )};
    }
`;

export const AlignCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const CardContainer = styled.div`
    width: calc(100% - 340px);
    max-width: 900px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const Titulo = styled.p`
    font-size: 36px;
    color: #9b43c4;
    text-align: center;
    text-transform: uppercase;

    font-weight: bold;

    margin-top: 10px;
    margin-bottom: 20px;
`;

export const Subtitulo = styled.p`
    font-size: 21px;
    font-weight: 100;
    color: grey;
`;

export const SimpleText = styled.p`
    font-size: 17px;
    font-weight: normal;
    color: #000;
    text-align: center;
`;

export const LogoContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const LogoVitrine = styled.img`
    width: 100%;
    max-width: 320px;
`;

export const VitrineTitulo = styled.p`
    font-size: 45px;
    font-weight: 900;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => props.vitrinecolor ? props.vitrinecolor : '#000'};

    margin-top: 0;
    margin-bottom: 0;
`;

// Components do Material
export const PurpleButton = styled(Button)`
   && {
       font-weight: bold;
       background: #9b43c4;
       color: white;

       padding: 10px 15px;

       &:hover{
        background: ${darken('#9b43c4', 0.1)};
       }
   } 
`;

export const TextButton = styled(Button)`
   && {
       font-size: 14px;
       font-weight: 600;
       color: ${props => props.btncolor ? props.btncolor : '#9b43c4'};
       text-align: left;
   } 
`;

export const SocialButton = styled(Button)`
   && {
       width: 30px;
       height: 30px;
       margin-top: 10px;
       margin-left: 10px;
       min-width: initial;
   } 
`;

export const InputBusca = styled(props => 
    <TextField InputProps={{ classes: { root: 'input-busca' } }} {...props}/>)`
   &&{
        width: 100%;
        border-radius: 4px 0 0 4px;
        height: 60px;
        
        label, input{
            line-height: 21px;
            color: #9b43c4;
        }
        
        .input-busca{
            height: 60px;
            border-radius: 4px 0 0 4px;
            background-color: white;

            &:hover{
                border-color: ${darken('#9b43c4', 0.1)};
            }
            
            fieldset{
                border-color: #9b43c4;
                border-radius: 4px 0 0 4px;
            }
        }
   }
`;

export const SearchBtn = styled(Link)`
   &&{
       color: #FFF;
       background-color: #9b43c4;
       min-width:64px;
       height: 60px;
       border-radius: 0 4px 4px 0;
       position: relative;
       top: 4px;

       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
   }
`;


export const MoreButton = styled(Button)`
    &&{
        width: 100%;
        max-width: 1024px;

        background-color: #FFF;
        margin-bottom: 20px;

        min-height: 60px;
        font-size: 24px;
        color: #34146f;
        background-color: #fff;
        border-radius: 0;
        border: 2px solid #34146f;
        margin-bottom: 20px;

        &:hover{
            border-color: ${darken('#34146f', 0.1)};
        }   
    }
`;

export const AnuncioContainer = styled.div`
    width: calc(100% - 350px);
    max-width: 924px;

    padding: 0 20px;

    @media screen and (max-width: 768px){
        width: 100%;
        max-width: initial;
        margin-bottom: 10px;
        padding: 0;
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    position: relative;

    &::after{
        font-size: 24px;
        content: '';
        content: ${ props => '"' + props.texto + '"' };
        padding: 0px 10px 5px 0px;
        border-bottom: ${props => '2px solid' + props.vitrinecolor };

        position: absolute;
        left: 0;
        bottom: 0;
    }

    @media screen and (max-width: 768px){
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        &::after{
            position: relative;
        }
    }
`;

export const Text = styled.div`
    font-size: 18px;
    margin-bottom: 30px;
    padding-left: 20px;
`;

export const IconsInfoContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const IconInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px;
`;


export const Icon = styled(props => 
    <div {...props}>
        <img src={props.src} />
    </div>)`
    width: 40px;
    height: 60px
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Info = styled.p`
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        font-size: 16px;
    }
`;

export const InfoSubtitulo = styled.p`
    font-size: 17px;
`;

export const EmailIcon = styled(props => 
    <MailOutline {...props}/>)`
    &&{
        font-size: 30px;
        color: #b00;
    }
`;

export const EmailShared = styled(props => 
    <a {...props}><EmailIcon /></a>)`
    &&{
        width: 50px;
        height: 50px;
        border: 1px solid #b00;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor: pointer;
        }
    }
`;

export const FacebookShared = styled(props => 
    <a {...props}><img src={Facebook} /></a>)`
    &&{
        width: 50px;
        height: 50px;
        border: 1px solid #3d5a99;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor: pointer;
        }

        img{
            width: 20px;
        }
    }
`;

export const TwitterShared = styled(props => 
    <a {...props}><img src={Twitter} /></a>)`
    &&{
        width: 50px;
        height: 50px;
        border: 1px solid #419cd6;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover{
            cursor: pointer;
        }

        img{
            width: 20px;
        }
    }
`;