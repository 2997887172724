import React from 'react';
import { CopyrightContainer } from './copyright-styled';

const Copyright = ({backgroundColor, textColor}) => {

    const getYear = () => {
        return new Date().getFullYear();
    }

    return(
        <>
            <CopyrightContainer backgroundColor={backgroundColor} textColor={textColor}>
                <p>® {getYear()} por Classi</p>
            </CopyrightContainer>
        </>
    )
};

export default Copyright;