import styled from 'styled-components';

export const Titulo = styled.p`
    font-size: 24px;
    font-weight: 200;
    text-align: center;

    width: 300px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;

    border-bottom: 2px solid #34146f;

    @media screen and (max-width: 768px){
        margin-right: auto;
        margin-top: 1.5em;
    }
`;

export const Label = styled.p`
   font-size: 16px;

   position: absolute;

   padding-top: 5px;
   margin-top: 0;
   margin-bottom: 0;

   @media screen and (max-width: 768px){
        margin-bottom: 5px;
    }
`;

export const Divisor = styled.div`
    width: 100%;
    max-width: 1024px;

    display: flex;
    border-bottom: 1px solid #616262;

    @media screen and (max-width: 768px){
        flex-direction: column;
        text-align: center;
    }
`;