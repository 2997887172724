import React, { Component } from 'react';
import { isMobile }         from './../../../../resources/methods/isMobile';

/**
* Hight-order component: checkScreenSize for media queries states
* 
* Check screen size and alter the state of screenType.
* The render will return a map of each children inside
* of this component with new parameter wich will update
* on every screen resize.
* @return {Component + state} props.screenType - boolean
*/

function HOCMediaQueries( WrappedComponent ){
  class WrappedWithScreenMobile extends Component{
    constructor(props){
      super(props);

      this.state = { screenMobile: false };
      this.checkScreenSize = this.checkScreenSize.bind(this);
    }

    componentDidMount(){
      window.addEventListener('resize', this.checkScreenSize);
      this.checkScreenSize();
    }
  
    componentWillUnmount(){
      window.removeEventListener('resize', this.checkScreenSize);
    }
  
    checkScreenSize(){
      if( isMobile.any() || window.innerWidth <= 1024 ){
        this.setState({ screenMobile: true });
      } else{
        this.setState({ screenMobile: false });
      }
    }

    render(){
      return(
        <WrappedComponent { ...this.props } screenMobile={ this.state.screenMobile } />
      )
    }
  }

  return WrappedWithScreenMobile;
}

export default HOCMediaQueries;