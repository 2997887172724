import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { FormControl } from '@material-ui/core';
import { Form } from 'formik';

const FilterCompact = `
    max-width: 900px;

    margin-left: auto;
    margin-right: auto;
`;

export const FilterContainer = styled.div`
    width: 100%;
    height: 115px;

    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#000'};
    padding: 40px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h1{ color: #FFF; }

    ${props => props.compact === 'true' ? FilterCompact : null };

    @media screen and (max-width: 768px){
        width: calc(100% - 20px);
        height: auto;
        padding: 0;
        background-color: #e7e7e7;
        flex-direction: column;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        border-radius: 2px;

        padding: 10px;

        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const FilterBtn = styled(Button)`
    &&{
        width: 150px;
        height: 40px;
        color: #000;
        background-color: #ecb12a;
        font-weight: bold;

        &:hover{
            background-color: #ecb12a;
        }
    }
`;

export const FilterSelect = styled(FormControl)`
    .MuiFormLabel-root{
        transform: translate(14px, 14px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink{
        opacity: 0;
    }

    &&{
        width: calc(33% - 5px);
        margin-top: 0;
        margin-bottom: 0;        

        fieldset {
            border: none;]            
        }

        .select{
            background-color: #eee;
            border: none;
            border-radius: 3px;
        }

        &.noMarginTop{
            margin-top: 0;
        }

        .MuiFormLabel-root{
            font-size: 15px;
        }

        .MuiSelect-root{
            padding: 10px;
        }

        @media screen and (max-width: 768px){
            width: calc(100% - 20px);
            height: 40px;
            
            margin-bottom: 15px;
            margin-left: auto;
            margin-right: auto;
            
            .select{
                background-color: #FFF;
                border: 1px solid #979797;
            }
        }
    }
`;

export const FilterForm = styled(Form)`
    width: 100%;
    max-width: ${props => props.compact === 'true' ? '850px' : '900px'};

    overflow: hidden;

    margin-left: auto;
    margin-right: auto;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    @media screen and (max-width: 768px){        
        height: auto;
        max-height: ${props => props.open ? '400px' : '0px'};
        transition: max-height .5s;
        flex-direction: column;
        align-items: center;
        margin-top: ${props => props.open ? '10px' : '0px'};
    }
`;

export const FilterToggleBtn = styled(Button)`
    &&{
        width: calc(100% - 20px);

        padding: 0;

        margin-left: auto;
        margin-right: auto;
        
        @media screen and (max-width: 768px){
            font-size: 16px;
        }
    }
`;