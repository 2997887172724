import { SET_CONTATADO } from './../actions/types';

const INITIAL_VALUE = {
    contatado: false
};

export default (state = INITIAL_VALUE, action) => {
    const { payload } = action;

    switch(action.type) {
        case SET_CONTATADO:
            return {
                ...state,
                contatado: payload
            }
        default:
            return state;
    }
}