export const FETCH_CURRENT_USER = 'fetch_current_user';
export const FETCH_GARAGEM_LAYOUT = 'fetch_garagem_layout';
export const FETCH_BUSCA = 'fetch_busca';
export const FETCH_ANUNCIO_BY_ID = 'fetch_anuncio_by_id';
export const FETCH_SEARCH_OBJECT = 'fetch_search_object';
export const SET_CONTATADO = 'set_contatado';
export const CONSULTAR_MARCAS_BY_CATEGORIA = 'consultar_marcas_by_categoria';
export const CONSULTAR_VEICULO_MODELO_BY_VEICULO_MARCA =
  'consultar_veiculo_modelo_by_veiculo_marca';
export const CONSULTA_ESTADOS = 'consulta_estados';
