import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import {
  CardPaper,
  CardInfo,
  CardTitulo,
  CardSubtitulo,
  CardLista,
  CardPrice,
  CardDivider,
  CardImageContainer,
} from './card-styled';
import { Hidden } from '@material-ui/core';
import Currency from 'react-currency-formatter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from 'react-router-dom';
import ImageRender from '../image-render/image-render';

const CardFullWidth = ({ info, defaultImg, defaultColor }) => {
  const matches = useMediaQuery('(max-width:768px)');

  const cardImgs = info.imagens.map((img) => img);

  const customImg = (item) => {
    return (
      <ImageRender
        src={item}
        width="300"
        height="300"
        className="image-gallery-img"
        data-object-fit="cover"
      />
    );
  };

  const location = useLocation();
  const [urlBase, setUrlBase] = useState();

  useEffect(() => {
    const paramBase = location ? location.pathname.split('/')[1] : '';
    const isSubdomain = window.location.href.includes('classi');

    if (isSubdomain) setUrlBase(paramBase);
  }, []);

  return (
    <>
      <CardPaper type="card-full-width" elevation={3}>
        <CardImageContainer
          type="card-full-width"
          defaultImg={defaultImg}
          defaultColor={defaultColor}
        >
          <ImageGallery
            items={cardImgs}
            renderItem={customImg.bind(this)}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            showNav={!matches}
            disableKeyDown={true}
          />
        </CardImageContainer>

        <CardInfo
          href={info ? `${urlBase ? '/' + urlBase : ''}${info.url}` : ''}
          target="_blank"
        >
          <CardTitulo>{info.titulo}</CardTitulo>
          <CardSubtitulo>
            {info.subtitulo ? info.subtitulo : null}
          </CardSubtitulo>

          <Hidden smDown>
            <CardLista>
              <li>{info.localizacao}</li>
              <li>{`${info.anoFabricacaoVersao} ${
                info.quilometragem ? '| ' + info.quilometragem + 'km' : ''
              } ${info.aceitaTroca ? '| ' + info.aceitaTroca : ''} `}</li>
              <li>{`${info.cambio ? 'Câmbio ' + info.cambio : ''} ${
                info.combustivel ? '| ' + info.combustivel : ''
              }`}</li>
            </CardLista>
          </Hidden>

          <Hidden mdUp>
            <CardSubtitulo>
              {info.anoFabricacaoVersao}
              <br />
              {info.quilometragem ? `${info.quilometragem} km` : ''}
            </CardSubtitulo>
            <CardPrice
              type="card-full-width"
              href={info ? `${urlBase ? '/' + urlBase : ''}${info.url}` : ''}
              target="_blank"
            >
              {info.valorCombinar ? 'Valor a combinar' : info.valor}
            </CardPrice>
          </Hidden>
        </CardInfo>

        <Hidden smDown>
          <CardDivider />

          <CardPrice
            type="card-full-width"
            href={info ? `${urlBase ? '/' + urlBase : ''}${info.url}` : ''}
            target="_blank"
          >
            {info.valorCombinar ? 'Valor a combinar' : info.valor}
          </CardPrice>
        </Hidden>
      </CardPaper>
    </>
  );
};

export default CardFullWidth;
