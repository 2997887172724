import React from 'react';
import CardMini from './card-mini';
import CardVertical from './card-vertical';
import CardFullWidth from './card-full-width';


const Card = ({ type, info, defaultImg, defaultColor }) => {
    switch(type) {
        case 'card-mini':
            return <CardMini info={info} defaultImg={defaultImg} defaultColor={defaultColor}/>
        case 'card-vertical':
            return <CardVertical info={info} defaultImg={defaultImg} defaultColor={defaultColor}/>
        case 'card-full-width':
            return <CardFullWidth info={info} defaultImg={defaultImg} defaultColor={defaultColor}/>
        default:
            return <CardMini info={info} defaultImg={defaultImg} defaultColor={defaultColor}/>
    };
}

export default Card;