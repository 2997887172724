import {
  FETCH_BUSCA,
  FETCH_SEARCH_OBJECT,
  CONSULTAR_MARCAS_BY_CATEGORIA,
  CONSULTA_ESTADOS,
  CONSULTAR_VEICULO_MODELO_BY_VEICULO_MARCA,
} from './../actions/types';

const INITIAL_VALUE = {
  filtros: null,
  searchObj: null,
  results: null,
  marcas: [],
  modelos: [],
  estados: [],
};

export default (state = INITIAL_VALUE, action) => {
  const { payload } = action;

  switch (action.type) {
    case FETCH_BUSCA:
      return {
        ...state,
        filtros: {
          marcas: payload.marcas,
          modelos: payload.modelos,
          estados: payload.estados,
          paginaAtual: payload.paginaAtual,
          qtdPaginas: payload.qtdPaginas,
        },
        anuncios: payload.anuncios,
      };
    case FETCH_SEARCH_OBJECT:
      return {
        ...state,
        searchObj: payload,
      };

    case CONSULTAR_MARCAS_BY_CATEGORIA:
      return {
        ...state,
        marcas: payload !== undefined ? payload : [],
      };

    case CONSULTAR_VEICULO_MODELO_BY_VEICULO_MARCA:
      return {
        ...state,
        modelos: payload !== undefined ? payload : [],
      };

    case CONSULTA_ESTADOS:
      return {
        ...state,
        estados: payload !== undefined ? payload : [],
      };

    default:
      return state;
  }
};
