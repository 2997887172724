import React, { Component } from 'react';

//Components
import SearchInput from './../search-input/search-input';
import SelectVitrine from './../select-vitrine/select-vitrine';

//Style
import { Container, Titulo } from '../commons/theme-styled';

//Imgs
import ImageBG from './../../../../resources/imgs/capa-poly-bg.jpg';

export default class SearchContainer extends Component {
    handleSearch() { }

    render() {
        return (
            <Container id="vitrines" backgroundImg={ImageBG} firstContainer={true} direction="column">
                <Titulo>O que você procura?</Titulo>

                <SearchInput defaultValue='' handleSearch={this.handleSearch} preventClick={false} />

                <SelectVitrine />

                {/* TODO: Traffic Container */}
            </Container>
        );
    }
}