import React from 'react';
import styled from 'styled-components';

export const Button = styled.button`
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
`;

export const LoginBtn = styled(Button)`
    font-weight: 700;
    color: #fff;
    border-color: #9b43c4;
    background-color: #9b43c4;
    padding: 12px 25px;
`;
export const LinkBtn = styled.a`
    font-size: 12px;
    color: ${props => props.btncolor ? props.btncolor : '#9b43c4'};
    float: right;
    text-align: right;
    cursor: pointer;
    line-height: 1px;

    &:hover{
        text-decoration: underline;
    }
`;

export const LogoutContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const LoginContainer = styled.div`
    
`;

export const Username = styled.a`
    font-size: 20px;
    color: ${props => props.btncolor ? props.btncolor : '#9b43c4'};
    padding: 0px 7px;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
`;