import React, { Component } from 'react';

// Styles
import { CopyrightContainer, Text } from './copyright-styled';
import {  } from '../footer/footer-styled';

export default class Copyright extends Component{

    render(){

        return(
            <CopyrightContainer direction="row" backgroundColor="#f2f2f2">
                <Text>
                    Copyright {(new Date().getFullYear())}, J. Câmara e Irmãos S/A, CNPJ: 01.536.754/0001-23 - Todos os direitos reservados.
                </Text>
            </CopyrightContainer>
        );

    }

}