import React from 'react';

import App from './App';
import Home from '../modules/capa/Home';
import Busca from '../modules/capa/Busca';
import Error404 from '../modules/errors/Error404';

// Garagem
import HomeGaragem, { loadData as GaragemLoadData} from './../modules/garagens/pages/Home';
import Estoque, { loadData as EstoqueLoadData } from '../modules/garagens/pages/Estoque';
import Anuncio, { loadData as AnuncioLoadData } from '../modules/garagens/pages/Anuncio';

const Routes = [
    {
        component: App,
        routes: [
            {
                component: Home,
                path: '/',
                exact: true
            },           
            {
                component: Busca,
                path: '/busca'
            },
            {
                component: Error404,
                path: '/404'
            },
            {
                component: HomeGaragem,
                path: '/template'
            },
            {
                component: Error404
            }
        ]
    }
];

export default Routes;