import React from 'react';

// Components
import Topbar from './../components/topbar/topbar';
import Banner from './../components/banner/banner';
import Filter from './../components/filter/filter';
import Contato from './../components/contato/contato';
import QuemSomos from './../components/quem-somos/quem-somos';
import Unidades from '../components/unidades/unidades';
import Copyright from '../components/copyright/copyright';
import Card from '../components/cards/card';
import { Element } from 'react-scroll';

// Mockup
import { template_1 } from './../../../resources/mockup/mockup-garagem';
import { CardContainer, LoadMoreBtn } from '../components/cards/card-styled';
import { useSelector, useDispatch } from 'react-redux';
import { FETCH_SEARCH_OBJECT } from '../../../actions/types';
import RedesSociais from '../components/redes-sociais/redesSociais';

const ListaTemplate = ({ layout, institucional, unidades }) => {
    const dispatch = useDispatch();
    
    const anuncios = useSelector(state => state.busca.anuncios);
    const searchObj = useSelector(state => state.busca.searchObj);
    const filtros = useSelector(state => state.busca.filtros);
    
    const loadMoreAnuncios = () => {
        if(filtros.qtdPaginas > filtros.paginaAtual){
            dispatch({
                type: FETCH_SEARCH_OBJECT,
                payload: {...searchObj, pagina: filtros.paginaAtual + 1}
            });
        }
    }

    return (
        <>
            <Topbar 
                type='simple'
                backgroundColor={layout.cor_principal}
                textColor={layout.cor_texto}
                textColorActive={layout.cor_text_ativo}
                logo={layout.logo}
                bannerDesktop={layout.banner_desktop}
                bannerMobile={layout.banner_mobile}
                isHome
            />
            <Banner 
                type='withInfo'
                logo={layout.logo}
                info={layout.planoInfo}
                endereco={layout.enderecoPrincipal}
                desktopImg={layout.banner_desktop}
                mobileImg={layout.banner_mobile}
            />
            <Filter type='compact' backgroundColor={layout.cor_principal}/>
            <CardContainer>
                {anuncios && anuncios.map(anuncio => (                    
                    <Card type='card-full-width' defaultImg={layout.logo} defaultColor={layout.cor_principal} info={anuncio}/>
                ))}

                {filtros && filtros.qtdPaginas > filtros.paginaAtual + 1 &&
                    <LoadMoreBtn textcolor={layout.cor_texto_botao} backgroundcolor={layout.cor_secundaria} variant="outlined" onClick={loadMoreAnuncios}>Carregar mais</LoadMoreBtn>
                }
            </CardContainer>
            <br />
            <Element name="quem-somos"/> 
            <QuemSomos
                lineColor={layout.cor_secundaria}
                imagem={institucional.imagem}
                titulo={institucional.titulo}
                texto={institucional.texto}
            />

            {layout.redesSociais &&
                <RedesSociais redes={layout.redesSociais} />
            }

            {unidades &&
                <>
                    <Element name="unidades"/> 
                    <Unidades
                        backgroundColor={layout.cor_principal}
                        textColor={layout.cor_texto}
                        lista={unidades}
                    />
                </>
            }
            <Copyright 
                backgroundColor={layout.cor_principal}
                textColor={layout.cor_texto}
            />
        </>
    )
}

export default ListaTemplate;