import React, { useState, useEffect } from 'react';
import { TopbarLogo, TopbarToggleWrapper } from './topbar-styled';
import Hamburguer from './../hamburguer/hamburguer';

const TopbarMobile = ({textColor, options, logo, closeWrapper}) => {
    const [openWrapper, setOpenWrapper] = useState();

    useEffect(() => {
        if(closeWrapper) setOpenWrapper(false);
    }, [closeWrapper]);

    return(
        <>
            <TopbarLogo src={logo} to="/"/>        

            <Hamburguer textColor={textColor} onClick={() => setOpenWrapper(!openWrapper)} open={openWrapper}/>

            <TopbarToggleWrapper open={openWrapper} windowHeight={window.innerHeight}>
                {options}
            </TopbarToggleWrapper>
        </>
    )
}

export default TopbarMobile;