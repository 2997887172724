import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Button, darken } from '@material-ui/core';
import ImageRender from '../image-render/image-render';

const CardMini = `
    width: 290px;
    height: 375px;

    .paper{
        display: flex;
        flex-direction: column;
    }

    .info-container {
        height: 40%;

        display: flex;
        flex-direction: column;
    }
`;

const CardVertical = `
    width: 440px;
    max-height: 200px;

    .paper{
        display: flex;
        flex-direction: row;
    }

    .info-container{
        width: 50%;
    }

    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 768px){
        height: 150px;
    }
`;

const CardFullWidth = `
    width: 100%;
    max-width: 900px;
    height: 180px;

    .paper{
        display: flex;
        flex-direction: row;
        align-items: center;

        border: 1px solid #979797;
        border-radius: 3px;
    }

    margin-left: auto;
    margin-right: auto;
`;

export const CardContainer = styled.div`
    width: 100%;
    max-width: 900px;

    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 768px){
        padding: 0 10px;
    }
`;

export const CardPaper = styled(props => (
    <div {...props}>
        <Paper  className='paper' elevation={props.elevation}>{props.children}</Paper>
    </div>
))`
    &&{
        .paper {
            width: 100%;
            height: 100%;

            display: flex;
            border-radius: 0;            
        }

        margin-bottom: 20px;

        @media screen and (min-width: 768px){
            ${props => props.type === 'card-mini' ? CardMini : null};
            ${props => props.type === 'card-vertical' ? CardVertical : null};
            ${props => props.type === 'card-full-width' ? CardFullWidth : null};
        }

        @media screen and (max-width: 768px){
            ${CardVertical}
        }
    }
`;

const CardImageFullWidth = `
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img{
        width: 100%;    
    }
`;

export const CardImageContainer = styled.div`
    width: 100%;
    height: ${props => props.type === 'card-mini' ? '60%' : null};

    line-height: 0;

    img{ 
        width: 100%; 
        min-height: ${props => props.type === 'card-vertical' ? null : '290px'}; 
    }

    .image-gallery, .image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe {
        width: 100%;
        height: 100%;
    }

    .image-gallery-slides {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .image-gallery-svg {
        width: 30px;
        height: 70px;
    }

    .image-gallery-left-nav {
        padding: 50px 0;
    }

    .image-gallery-bullet {
        padding: 2px;

        &.active{ background-color: red; }
    }

    .image-gallery-bullets {
        bottom: 7px;
    }

    ${props => props.defaultImg && `
        background: url(${props.defaultImg});
        background-color: ${props.defaultColor ? props.defaultColor : '#000000'};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
    `};

    @media screen and (min-width: 768px){
        ${ props => props.type === 'card-vertical' ? 'width: 50%' : null };
        ${ props => props.type === 'card-full-width' ? CardImageFullWidth : null };
    }

    @media screen and (max-width: 768px){
        width: 50%;
        height: 100%;

        img{
            min-height: inherit;
        }
    }
`;

export const CardImage = styled(props => (
    <div {...props}>
        <ImageRender src={props.src} width="300" height="300" />
    </div>
))`
    width: 100%;
    line-height: 0;

    img{ 
        width: 100%; 
        min-height: ${props => props.type === 'card-vertical' ? null : '290px'}; 
    }

    ${props => props.defaultImg && `
        background: url(${props.defaultImg});
        background-color: ${props.defaultColor ? props.defaultColor : '#000000'};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;
    `};
    
    @media screen and (min-width: 768px){
        ${ props => props.type === 'card-vertical' ? 'width: 50%' : null };
        ${ props => props.type === 'card-full-width' ? CardImageFullWidth : null };
    }

    @media screen and (max-width: 768px){
        width: 50%;

        img{
            min-height: inherit;
        }
    }
`;

export const CardInfo = styled(props => (
    <a {...props}>
        <div>{props.children}</div>
    </a>
))`
    width: 50%;
    height: 100%;

    color: #212121;
`;

export const CardTitulo = styled.p`
    font-size: 21px;
    font-weight: bold;
    color: #212121;

    margin: 5px 10px;

    ${props => props.type === 'card-mini' && 
        `text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;`      
    }

    @media screen and (max-width: 768px){
        font-size: 18px;
        max-height: 40px;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

export const CardLista = styled.ul`
    color: #666;
`;

export const CardSubtitulo = styled.p`
    font-size: 16px;
    color: #666;

    margin: 5px 10px;
`;

const CardPriceFullWidth = `
    width: calc(50% - 250px);
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-left: auto;
    margin-right: auto;
`

export const CardPrice = styled(props => (
    <div {...props}>
        <p>{props.children}</p>
    </div>
))`
    p {
        font-size: ${props => props.type === 'card-full-width' ? '26px' : '31px'};
        font-weight: bold;
        margin: ${props => props.type === 'card-full-width' ? null : '5px 10px'} ;
    }

    color: #212121;
    margin: 15px 10px;

    ${props => props.type === 'card-mini' && `
        margin-top: auto;
        margin-bottom: 5px;
        margin-left: 0;
    `}

    @media screen and (min-width: 768px){
        ${props => props.type === 'card-full-width' ? CardPriceFullWidth : null};
    }

    @media screen and (max-width: 768px){
        p {
            font-size: 21px;
            margin: 5px 10px;
        }
    }
`;

export const CardDivider = styled.div`
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;

    border-right: 1px solid #666;
`;

export const LoadMoreBtn = styled(Button)`
    &&{
        width: 100%;
        font-size: 21px;
        color: ${props => props.textcolor ? props.textcolor : '#000'};
        background-color: ${props => props.backgroundcolor ? props.backgroundcolor : '#FFF'};

        padding: 20px;

        &:hover{
            background-color: ${props => props.backgroundcolor ? darken(props.backgroundcolor, 0.2) : darken('#FFF', 0.2)};
        }
    }
`;