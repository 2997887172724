import React, { useEffect, useState } from 'react';
import { FilterForm, FilterSelect } from './filter-styled';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { consultarModelosByMarca } from '../../../../actions';

export default ({
  handleSubmit,
  filtros,
  marcas,
  modelos,
  estados,
  openWrapper,
  type,
}) => {
  const dispatch = useDispatch();
  const { setValues, values } = useFormikContext();
  const [oldValues, setOldValues] = useState();

  useEffect(() => {
    if (values && values !== oldValues) {
      setOldValues(values);
      handleSubmit();
    }
  }, [values]);

  const selectMarca = (e) => {
    dispatch(consultarModelosByMarca(e.target.value));
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      modelo: null,
    });
  };

  const selectCommon = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <FilterForm
      open={openWrapper}
      compact={Boolean(type === 'compact').toString()}
    >
      <FilterSelect
        variant="outlined"
        compact={Boolean(type === 'compact').toString()}
      >
        <InputLabel>Marcas</InputLabel>
        <Select
          className="select"
          name="marca"
          value={values.marca || ''}
          onChange={selectMarca}
        >
          <MenuItem value={null}>Todos</MenuItem>
          {marcas &&
            marcas.map((marca) => (
              <MenuItem key={marca.valor} value={marca.valor}>
                {marca.descricao}
              </MenuItem>
            ))}
        </Select>
      </FilterSelect>

      {/* <FilterSelect variant="outlined" disabled={!Boolean(filtros.modelos)}> */}
      <FilterSelect variant="outlined">
        <InputLabel>Modelo</InputLabel>
        <Select
          className="select"
          name="modelo"
          value={values.modelo || ''}
          onChange={selectCommon}
        >
          <MenuItem value={null}>Todos</MenuItem>
          {modelos &&
            modelos.map((modelo) => (
              <MenuItem key={modelo.valor} value={modelo.valor}>
                {modelo.descricao}
              </MenuItem>
            ))}
        </Select>
      </FilterSelect>

      <FilterSelect variant="outlined">
        <InputLabel>Estado</InputLabel>
        <Select
          className="select"
          name="estado"
          value={values.estado || ''}
          onChange={selectCommon}
        >
          <MenuItem value={null}>Todos</MenuItem>
          {estados &&
            estados.map((estado) => (
              <MenuItem key={estado.valor} value={estado.valor}>
                {estado.descricao}
              </MenuItem>
            ))}
        </Select>
      </FilterSelect>
    </FilterForm>
  );
};
