import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Components
import Grid from '@material-ui/core/Grid';

//Styles
import { Card, Container, Bairro, Endereco } from './autorizadas-styled';

export default class Autorizadas extends Component{
    static propTypes = {
        lojas: PropTypes.array
    }

    static defaultProps = {
        lojas: []
    }

    render(){
        const { lojas } = this.props;
        return(
            <Container>
                <Grid item container xs={12} md={8} justify="flex-start" spacing={8}>
                    { (lojas || []).map(loja => 
                        <Grid key={loja.id} item md={4} xs={12}>
                            <Card>
                                {loja.bairro && <Bairro>{loja.bairro}</Bairro>}
                                {loja.endereco && 
                                    <React.Fragment>
                                        <Endereco>{loja.endereco}</Endereco>
                                        <br /><br />
                                    </React.Fragment>
                                }
                                {loja.telefone && <Endereco>{loja.telefone}</Endereco>}
                            </Card>
                        </Grid>  
                    )}
                </Grid>
            </Container>
        );

    }

}