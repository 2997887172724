import React from 'react';
import { TopbarMenu, TopbarMenuItem, TopbarDivider, TopbarLogo, TopbarNumber } from './topbar-styled';

const TopbarWithLogo = ({ options, logo, phone }) => {
    return(
        <>
            <TopbarLogo src={logo} to="/"/>

            {options}

            {phone &&
                <TopbarNumber>
                    {phone}
                </TopbarNumber>
            }
        </>
    )
};

export default TopbarWithLogo;