import React, { Component } from 'react';
import Hamburguer from '../hamburguer/hamburguer';
import SelectVitrine from '../select-vitrine/select-vitrine';
import Hidden from '@material-ui/core/Hidden';
import Login from './../login/login';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { userLogout } from './../../../../actions';

//Styled
import { 
    Divisor, 
    AnchorContainer, 
    AnchorButton,
    TopbarContainer, 
    TopbarWrapper, 
    TopbarFilter, 
    TopbarContent, 
    Logo, 
    LinkContainer, 
    Overlay, 
    TituloBtn, 
    Subtitulo, 
    SearchIcon, 
    TopbarFilterBtn 
} from './topbar-styled';

import { TextButton, Titulo } from '../commons/theme-styled';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

//Imgs
import background from './../../../../resources/imgs/capa-poly-bg.jpg';
import LogoClassi from './../../../../resources/svg/logo-classi.svg';
import { propTypes } from 'react-currency-format';

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            root: {
                display: 'block',
                textAlign: 'left'
            }
        }
    }
});

class Topbar extends Component {
    state = {
        textColor: '',
        openWrapper: false,
        openfilter: false,
        openOverlay: false
    };

    static defaultProps = {
        showAnchor: false,
        backgroundColor: '#FFF',
        filters: propTypes.func
    }

    componentDidMount(){
        if(this.props.backgroundColor != '#FFF'){
            this.setState( { textColor: '#FFF' });
        }
    }

    toggleWrapper = (e) => {
        e.preventDefault();

        const { openWrapper, openOverlay } = this.state;

        if(openOverlay){
            this.setState({ openWrapper: false, openfilter: false, openOverlay: false });
        } else {
            this.setState({ openWrapper: !openWrapper, openOverlay: true });
        }
    };

    toggleFilter = (e) => {
        e.preventDefault();

        const { openfilter, openOverlay } = this.state;

        if(openOverlay){
            this.setState({ openWrapper: false, openfilter: false, openOverlay: false });
        } else {
            this.setState({ openfilter: !openfilter, openOverlay: true });
        }
    };

    render(){
        const showAnchor = this.props.withAnchor;
        let anchorContainer;

        const { authentication, location, userLogout } = this.props;
        
        let Filters = this.props.filters;

        if(showAnchor){
            anchorContainer = 
            <AnchorContainer >
                <AnchorLink href="#vitrines" offset='100'>
                    <AnchorButton>
                        <p>Ir para</p>
                        <p>Vitrines</p>
                    </AnchorButton>
                </AnchorLink>

                <AnchorLink href="#anuncie" offset='50'>
                    <AnchorButton>
                        <p>Criar</p>
                        <p>Anúncio</p>
                    </AnchorButton>
                </AnchorLink>

                <AnchorLink href="#planos" offset='100'>
                    <AnchorButton>
                        <p>Acesse seu</p>
                        <p>Plano</p>
                    </AnchorButton>
                </AnchorLink>
            </AnchorContainer>
        } else{
            anchorContainer = 
            <AnchorContainer>
                <TextButton href={process.env.URL_PLANOS} btncolor={ this.state.textColor }>Acesse seu plano</TextButton>
                <TextButton href={process.env.ACCOUNTS_SIGNUP} btncolor={ this.state.textColor }>Cadastre-se</TextButton>
                <TextButton href={process.env.URL_ANUNCIE} btncolor={ this.state.textColor }>Anuncie</TextButton>
            </AnchorContainer>
        }

        return(
            <div>
                <TopbarContainer backgroundColor={ this.props.backgroundColor }>
                    <TopbarContent>
                        { this.props.withAnchor ? null :
                            <Hamburguer onClick={ this.toggleWrapper } open={ this.state.openWrapper }></Hamburguer>
                        }

                        <LinkContainer to='/' >
                            <Logo src={ LogoClassi } />
                        </LinkContainer>

                        <Hidden xsDown>
                            { anchorContainer }
                            <Login 
                                btncolor={ this.state.textColor }
                                userLogout={userLogout}
                                routerLocation={location ? location.pathname : ''}
                                authentication={authentication}  
                            />       
                        </Hidden>

                        <Hidden smUp>
                            { this.props.busca ? 
                                <SearchIcon onClick={ this.toggleFilter } />
                                : null
                            }

                            { this.props.withAnchor ?
                                <Login 
                                    btncolor={ this.state.textColor }
                                    userLogout={userLogout}
                                    routerLocation={location ? location.pathname : ''}
                                    authentication={authentication}
                                />
                                : null 
                            }
                        </Hidden>

                    </TopbarContent>

                    <TopbarWrapper bgimage={ background } isopen={ this.state.openWrapper }>
                        <Hidden smUp>
                            <Login 
                                btncolor="#9b43c4"
                                userLogout={userLogout}
                                routerLocation={location ? location.pathname : ''}
                                authentication={authentication}
                            />
                            <Divisor />
                        </Hidden>

                        <Titulo>O que você procura?</Titulo>
                        <SelectVitrine />
                    </TopbarWrapper>

                    <TopbarFilter isopen={ this.state.openfilter }>
                        {this.props.filters ? 
                            <Filters />
                            : null
                        }

                        <TopbarFilterBtn btncolor={ this.props.backgroundColor }>
                            Aplicar
                        </TopbarFilterBtn>
                    </TopbarFilter>
                </TopbarContainer>

                <Overlay isopen={ this.state.openOverlay }/>
            </div>
        );

    }

}

const mapStateToProps = store => ({
    authentication: store.authentication.authentication
})

export default connect(mapStateToProps, { userLogout })(Topbar);