import React, { Component } from 'react';

//Styles
import { Card, Icon, Info, Titulo, Subtitulo, Label, Video } from './video-container-styled'
import { Container } from '../commons/theme-styled';

//Icons
import Bitcoin from './../../../../resources/svg/bitcoin.svg';
import Cartao from './../../../../resources/svg/cartao.svg';

export default class VideoContainer extends Component{

    render(){

        return(
            <Container backgroundColor="#400f50" direction="row">
                <Video
                    playsInline
                    src="https://cdn.ojc.com.br/classificados/home/video.mp4"
                />
                
                <div>
                    <Card>
                        <Icon src={Bitcoin} />
                        <Info>
                            <Titulo>Quanto custa anunciar?</Titulo>
                            <Subtitulo>Anúncios a partir de R$3*.</Subtitulo>
                            <Label>*Assinantes do Jornal O Popular possuem 10% de desconto em anúncios de linha.</Label>
                        </Info>
                    </Card>

                    <Card>
                        <Icon src={Cartao} />
                        <Info>
                            <Titulo>Pague com o cartão de crédito</Titulo>
                            <Label>Fácil, rápido, seguro e com o sigilo bancário que você deseja.</Label>
                        </Info>
                    </Card>
                </div>
            </Container>
                
        )

    }

}