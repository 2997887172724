import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import React from 'react';
import { NavLink } from 'react-router-dom';

const TopbarSimple = `
    border-bottom: 1px solid #717171;
`;

export const TopbarContainer = styled.div`
    width: 100%;
    height: 95px;

    background-color: ${props => props.backgroundcolor ? props.backgroundcolor : '#FFF'};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${props => props.simpleTopbar ? TopbarSimple : null };

    @media screen and (max-width: 768px){
        height: 50px;
        justify-content: space-between;
    }

    .topbar-content {
        width: 100%;
        height: 100%;
        max-width: 900px;

        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 768px){
            justify-content: space-between;
        }
    }
`;

export const TopbarSimpleContainer = styled.div`
    width: 100%;
    max-width: 900px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const TopbarMenu = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 768px){
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        margin-top: auto;
        margin-bottom: auto;
    }
`;

export const TopbarMenuItem = styled(props => (
    <NavLink {...props}>
        <Button className="button">{props.children}</Button>
    </NavLink>
))`
    &&{
        .button{
            height: 20px;
            font-size: 18px;
            font-weight: normal;
            text-transform: none;
            color: ${props => props.color ? props.color : '#000'};
            padding: 0 10px;
            border-radius: 0;
            line-height: 0;

            @media screen and (max-width: 768px){
                width: 100%;
                height: 100px;
                font-size: 31px;
                font-weight: bold;
            }
        }
        
        &.currentPath .button {
            color: ${props => props.activecolor ? props.activecolor : '#000'}
        }

    }
`;

export const TopbarDivider = styled.div`
    width: 1px;
    height: 20px;

    border-left: 1px solid #000;

    @media screen and (max-width: 768px){
        width: 100%;
        height: 1px;

        border-bottom: 1px solid #000;
        border-left: none;        
    }
`;

export const TopbarLogo = styled(props => (
    <NavLink to={props.to}>
        <img {...props} />
    </NavLink>
))`
    width: 120px;
    max-height: 100%;
    margin-right: 10px;

    @media screen and (max-width: 768px){
        margin-left: 10px;
    }
`;

export const TopbarNumber = styled.span`
    font-size: 21px;
    font-weight: bold;
    color: #FFF;
`;

export const TopbarToggleWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #ecb12a;

    max-height: ${props => props.open ? `${props.windowHeight}px`  : '0px'};
    
    transition: max-height .5s ease;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0px;
    z-index: 99;
`;