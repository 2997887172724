// TODO: Criar um component de breakpoint

import styled from 'styled-components';
import { Player } from 'video-react';

export const Video = styled(Player)`
    &&&{
        width: 500px;
        height: 280px;
        padding-top: 0 !important;

        .video-react-big-play-button{
            margin-top: -0.75em;
            margin-bottom: -0.75em;
            margin-left: -1.5em;
            margin-right: -1.5em;
            left: 50%;
            right: 50%;
            top: 50%;
            bottom: 50%;
        }
    }
`;

export const Card = styled.div`
    width: 100%;
    max-width: 370px;
    display: flex;
    align-items: center;

    margin-bottom: 20px;
    margin-left: 20px;

    @media screen and (max-width: 425px){
        margin-left: 0;
        margin-top: 20px;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-align: center;
    }
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
`;

export const Icon = styled.img`
    width: 60px;
    min-width: 60px;
    height: 55px;
`;

export const Titulo = styled.h3`
    font-size: 21px;
    font-weight: 700;
    color: #FFF;

    margin-top: 0;
    margin-bottom: 0;
`;

export const Subtitulo = styled.h4`
    font-size: 16px;
    font-weight: 700;
    color: #FFF;
    margin-top: 0;
    margin-bottom: 0;
`

export const Label = styled.span`
    font-size: 12px;
    color: #FFF;
`;