import styled from 'styled-components';

export const QuemSomosContainer = styled.div`
    width: 100%;
    max-width: 900px;
    padding: 20px;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
`;

export const Titulo = styled.p`
    width: 100%;

    font-size: 35px;
    color: #7d7d7d;
    padding-bottom: 10px;
    border-bottom: 2px solid ${props => props.lineColor ? props.lineColor : '#000'};
`;

export const QuemSomosImg = styled.img`
    width: 50%;
    max-width: 400px;

    @media screen and (max-width: 768px){
        width: 100%;
    }
`;

export const QuemSomosText = styled.span`
    width: 50%;
    max-width: 400px;

    font-size: 16px;
    color: #888;
    line-height: 21px; 

    margin-left: 20px;

    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 10px;
        margin-left: 0;
        margin-right: 0;
        text-align: justify;
    }
`;