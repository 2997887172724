import React from 'react';
import { BannerContainer, BannerImage } from './banner-styled';

import BannerWithInfo from './banner-with-info';
import { Hidden } from '@material-ui/core';

const Banner = ({ info, type, desktopImg, mobileImg, logo, endereco }) => {
    return (
        <>
            <BannerContainer withInfo={Boolean(type === 'withInfo')}>
                {type === 'fullWidth' &&
                    <>
                        <Hidden smDown><BannerImage src={desktopImg}/></Hidden>                        
                        <Hidden smUp><BannerImage src={mobileImg}/></Hidden>                        
                    </>
                }
                {type === 'withInfo' &&
                    <BannerWithInfo info={info} logo={logo} banner={desktopImg} endereco={endereco}/>
                }
            </BannerContainer>
        </>
    )
};

export default Banner;