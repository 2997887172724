import React, { Component } from 'react';

//Style
import { Container, SimpleText } from '../commons/theme-styled';

// Imgs
import ButtonToCard from '../button-to-card/buttonToCard';

export default class AnuncieContainer extends Component{

    render(){
        return (
            <Container id="anuncie" direction="column">
                {/* TODO: Passar todos os textos para um unico arquivo */}

                <ButtonToCard />

                <SimpleText >
                    Grátis até 3 anúncios por CPF, para veiculação na plataforma digital (site Classi). Demais preços,
                    <br />
                    promoções e condições de pagamento válidos exclusivamente para compras efetuadas neste site, podendo apresentar 
                    <br />
                    variações em lojas físicas e telemarketing. Todas as condições comerciais estão sujeitas a alterações sem aviso prévio.
                </SimpleText>
            </Container>
        );
    }

}