import React from 'react';
import styled from 'styled-components';
import { Form } from 'formik';
import { Button, darken, TextField, FormControl } from '@material-ui/core';

export const ContatoContainer = styled.div`
    width: 100%;
    padding: 0 30px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 768px){
        padding: 0 10px;
        flex-direction: column;
    }

    a{ color: #000; text-decoration: underline !important; }
`;

export const ContatoButton = styled(Button)`
    &&{
        width: calc(50% - 10px);
        height: 50px;
        line-height: 1;
        background-color: #FFF;            
        padding: 15px;
        font-weight: bold;
        text-transform: initial;

        &:hover{
            background-color: ${darken('#FFFFFF', 0.2)};
        }

        &.whatsapp{
            color: #4caf50;
        }

        &.phone{
            color: #3140a8;
        }

        @media screen and (max-width: 768px){
            width: 100%;
            margin-bottom: 10px;
        }
    }
`;

export const ContatoMensagemContainer = styled.div`
    width: 100%;
    border: 2px solid #838383;
    margin-top: 10px;
`;

export const ContatadoTexto = styled.p`
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
`;

export const ContatoForm = styled(Form)`
    width: 100%;

    padding: 10px;
    border: 2px solid #838383;

    margin-top: 10px;

    .captcha{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 768px){
        margin-bottom: 0;
    }
`;

export const ContatoInput = styled(TextField)`
    &&{
        width: 100%;
        background-color: #FFF;
        border-radius: 0;
        margin-bottom: 10px;

        .MuiInputBase-root {
            border-radius: 0;
        }
    }
`;

export const ContatoSelect = styled(FormControl)`
    &&{
        width: 100%;
        background-color: #FFF;
        border-radius: 0;
        margin-bottom: 10px;

        .MuiInputBase-root {
            border-radius: 0;
        }
    }
`;

export const ContatoBtn = styled(Button)`
    &&{
        width: 100%;
        height: 40px;
        border-radius: 0;
        font-weight: bold;
        color: ${props => props.textColor ? props.textColor : '#000'};
        background-color: ${props => props.buttonColor ? props.buttonColor : "#FFF"};

        &:hover{
            background-color: ${props => props.buttonColor ? darken(props.buttonColor, 0.2) : darken('#FFF', 0.2)}; ${darken('#ecb12a', 0.2)};
        }
    }
`;

export const ContatoIcon = styled.img`
    height: 20px;
    margin-right: 5px;
`;