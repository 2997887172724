import {
  FETCH_GARAGEM_LAYOUT,
  FETCH_BUSCA,
  FETCH_ANUNCIO_BY_ID,
  SET_CONTATADO,
  CONSULTAR_MARCAS_BY_CATEGORIA,
  CONSULTA_ESTADOS,
  CONSULTAR_VEICULO_MODELO_BY_VEICULO_MARCA,
} from './types';
import axios from 'axios';

export const fetchGaragemLayout =
  ({ hostName, nome, params }) =>
  async (dispatch) => {
    let requestContext = undefined;

    if (params) {
      dispatch({
        type: FETCH_GARAGEM_LAYOUT,
        payload: JSON.parse(decodeURI(params)),
      });
    } else {
      await axios
        .get(
          `${process.env.API_URL}/plano-contrato/site-anunciante?${
            nome && nome !== 'estoque' && nome !== 'anuncio'
              ? `nome=${nome}`
              : `hostName=${hostName}`
          }`
        )
        .then((res) => {
          dispatch({
            type: FETCH_GARAGEM_LAYOUT,
            payload: res.data,
          });
        })
        .catch((error) => {
          requestContext = Object.assign({}, { error: { status: '404' } });
        });
    }

    return requestContext;
  };

export const fetchBusca =
  ({ filter }) =>
  async (dispatch, getState) => {
    let requestContext = undefined;
    const state = getState();

    filter.anunciante = state.template.garagemId;

    await axios
      .post(
        `${process.env.API_URL}/vitrine-veiculos/consultaVitrineVeiculos`,
        filter
      )
      .then((res) => {
        dispatch({
          type: FETCH_BUSCA,
          payload: res.data,
        });
      });

    return requestContext;
  };

export const consultarMarcasByCategoria = (idCategoria) => async (dispatch) => {
  try {
    await axios
      .get(
        `${process.env.API_URL}/vitrine-veiculos/consultarMarcasByCategoria/${idCategoria}`
      )
      .then((res) => {
        dispatch({
          type: CONSULTAR_MARCAS_BY_CATEGORIA,
          payload: res.data,
        });
      });
  } catch (error) {
    requestContext = Object.assign(
      {},
      {
        error: error.response.data,
      }
    );
  }
};

export const consultarModelosByMarca = (idMarca) => async (dispatch) => {
  try {
    await axios
      .get(
        `${process.env.API_URL}/vitrine-veiculos/consultarVeiculoModeloByVeiculoMarca/${idMarca}`
      )
      .then((res) => {
        dispatch({
          type: CONSULTAR_VEICULO_MODELO_BY_VEICULO_MARCA,
          payload: res.data,
        });
      });
  } catch (error) {
    requestContext = Object.assign(
      {},
      {
        error: error.response.data,
      }
    );
  }
};

export const consultaEstados = () => async (dispatch) => {
  try {
    await axios
      .get(`${process.env.API_URL}/vitrine-veiculos/consultaEstados`)
      .then((res) => {
        dispatch({
          type: CONSULTA_ESTADOS,
          payload: res.data,
        });
      });
  } catch (error) {
    requestContext = Object.assign(
      {},
      {
        error: error.response.data,
      }
    );
  }
};

export const fetchAnuncioById = (anuncioId) => async (dispatch) => {
  let requestContext = undefined;

  await axios
    .get(`${process.env.API_URL}/anuncio-digital/find/${anuncioId}`)
    .then((res) => {
      dispatch({
        type: FETCH_ANUNCIO_BY_ID,
        payload: res.data,
      });
    })
    .catch((error) => {
      requestContext = Object.assign({}, { error: { status: '404' } });
    });

  return requestContext;
};

export const sendContato = (objetoContato) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.API_URL}/vitrine-veiculos/sendMessage`,
        objetoContato
      )
      .then((res) => {
        resolve();
      });
  });

export const getUserInfo = () => async (dispatch, getState, api) => {
  //Tal requisição somente é para ocorrer client side e logado.
  if (typeof window !== 'undefined') {
    if (window.keycloak && window.keycloak.token) {
      await axios
        .get(`${process.env.API_URL}/auth/userinfo`, {
          headers: {
            Authorization: `Bearer ${window.keycloak.token}`,
          },
        })
        .then((response) => {
          dispatch({
            type: FETCH_USER_INFO,
            payload: response.data,
          });
        })
        .catch((error) => {
          requestContext = Object.assign(
            {},
            {
              error: error.response.data,
            }
          );
        });
    }
  }
};

// Authentication
export const getUser = () => async (dispatch, getState, api) => {
  await api
    .get('/accounts')
    .then((response) => {
      dispatch({
        type: FETCH_CURRENT_USER,
        payload: response.data,
      });
    })
    .catch((err) => {
      // dispatch({ type: DELIVER_DEFAULT_USER });
      throw new Error(err);
    });
};

export const userLogout = () => async (dispatch, getState, api) => {
  // dispatch({ type: DELIVER_DEFAULT_USER });
  await api.get('/logout');
};
