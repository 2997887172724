import styled from 'styled-components';

export const Text = styled.p`
    width: 500px;
    font-size: 24px;
    font-weight: bold;
    color: #4a4a4a;

    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        width: 100%;
        text-align: center;

        margin-bottom: 20px;
    }
`;

export const Image = styled.img`
    width: 400px;
`;