import React, { Component } from 'react';

import { initAnalytics, enviarPageView } from '../commons/components/analytics-gjc/analytics-gjc';

//Components
import Footer from './component/footer/footer';
import Copyright from './component/copyright/copyright';
import SearchContainer from './component/search-container/search-container';
import AnuncieContainer from './component/anuncie-container/anuncie-container';
import TextContainer from './component/text-container/text-container';
import VideoContainer from './component/video-container/video-container';
import PlanoContainer from './component/plano-container/plano-container';
import Autorizadas from './component/autorizadas/autorizadas';
import Hidden from '@material-ui/core/Hidden';
import { Container } from './component/commons/theme-styled';
import Topbar from './component/topbar/topbar';
import { getLojas } from './../../api/api';
import GPTComponent from './component/gpt/GPT';
import { Helmet } from 'react-helmet';

// FavIcon
import FavIcon32 from './../../resources/imgs/favicons/homescreen-V2_favicon-32x32.png';
import FavIcon76 from './../../resources/imgs/favicons/homescreen-V2_ios-76x76.png';

// TODO: Substituir tags tipografia para se adequar ao material-ui: https://material-ui.com/style/typography/#migration-to-typography-v2
class Home extends Component {
  state = {
    lojas: []
  }

  componentDidMount() {

    initAnalytics('capa', process.env.SITEID_ANALYTICS);
    enviarPageView();

    getLojas().then(response => {
      this.setState({ lojas: response.autorizadas })
    });

  }

  render() {
    return (
      <>
        <Helmet>
          <link rel="icon" type="image/png" href={FavIcon32} />
          <link rel="apple-touch-icon" sizes="76x76" href={FavIcon76} />
        </Helmet>

        <Container direction="column" noPadding={true}>
          <Topbar withAnchor={true}></Topbar>

          {/* Intervenção */}
          <GPTComponent gtpId="intervencao" gpt="intervencao" />

          <SearchContainer />

          <AnuncieContainer />

          <VideoContainer />

          <Hidden xsDown>
            <TextContainer text="Os anúncios estão bombando, invista no seu negócio também!" />

            <PlanoContainer />

            <TextContainer text="É rápido e fácil. Seu anúncio em O Popular Jornal Daqui e na internet." />
          </Hidden>

          <Autorizadas lojas={this.state.lojas} />
        </Container>
        <Footer />
        <Copyright />
      </>
    );
  }
}

export default Home;