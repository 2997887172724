import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 30px 0;
    display: flex;
    justify-content: center;
`;

export const Card = styled.div`
    height: 170px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(224, 212, 255);
    border-image: initial;
    border-radius: 5px;
    padding: 10px;

    &:last-child{
        margin-right: auto;
    }
`;

export const Bairro = styled.p`
    color: rgb(155, 67, 196);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;

    margin-top: 0;
    margin-bottom: 10px;
`;

export const Endereco = styled.span`
    font-size: 16px;
    color: rgb(51, 51, 51);
`;