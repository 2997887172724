import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

//Component
import SearchIcon from '@material-ui/icons/Search';

//Styled
import { InputContainer } from './search-input-styled';
import { InputBusca, SearchBtn } from '../commons/theme-styled';


class SearchInput extends Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: '' };

        this.updateInput = this.updateInput.bind(this);
        this.search = this.search.bind(this);
        this.callSearch = this.callSearch.bind(this);
    }

    componentDidMount() {
        this.setState({ searchValue: this.props.defaultValue });
    }

    componentDidUpdate(prevProps) {

        if (prevProps != this.props) {
            this.setState({ searchValue: this.props.defaultValue });
        }

    }

    search(e) {
        if (this.props.preventClick) {
            e.preventDefault();
        }

        this.props.handleSearch(this.state.searchValue);
    }

    updateInput(e) {
        e.preventDefault();

        this.setState({
            searchValue: e.target.value
        });
    }

    callSearch(e) {
        if (e.key === 'Enter') {
            this.props.history.push({
                pathname: '/busca',
                search: `?texto=${this.state.searchValue}`
            });
        }
    }

    render() {
        return (
            <InputContainer onSubmit={(e) => e.preventDefault()}>
                <InputBusca
                    label="Digite sua busca"
                    type="search"
                    margin="normal"
                    variant="outlined"
                    value={this.state.searchValue}
                    onChange={this.updateInput}
                    onKeyPress={this.callSearch}
                />

                <SearchBtn
                    variant="contained"
                    to={{ pathname: '/busca', search: `?texto=${this.state.searchValue}` }}
                    onClick={this.search}
                >
                    <SearchIcon />
                </SearchBtn>
            </InputContainer>
        );
    }
}

export default withRouter(SearchInput);