import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import {
  CardPaper,
  CardTitulo,
  CardSubtitulo,
  CardPrice,
  CardImageContainer,
} from './card-styled';
import Currency from 'react-currency-formatter';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from 'react-router-dom';
import ImageRender from '../image-render/image-render';

const CardVertical = ({ info, defaultImg, defaultColor }) => {
  const matches = useMediaQuery('(max-width:768px)');

  const cardImgs = info.imagens.map((img) => img);

  const customImg = (item) => {
    return (
      <ImageRender
        src={item}
        width="300"
        height="300"
        className="image-gallery-img"
        data-object-fit="cover"
      />
    );
  };

  const location = useLocation();
  const [urlBase, setUrlBase] = useState();

  useEffect(() => {
    const paramBase = location ? location.pathname.split('/')[1] : '';
    const isSubdomain = window.location.href.includes('classi');

    if (isSubdomain) setUrlBase(paramBase);
  }, []);

  return (
    <>
      <CardPaper type="card-vertical" elevation={3} to={info ? info.url : null}>
        <CardImageContainer
          type="card-full-width"
          defaultImg={defaultImg}
          defaultColor={defaultColor}
        >
          <ImageGallery
            items={cardImgs}
            renderItem={customImg.bind(this)}
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            showNav={!matches}
            disableKeyDown={true}
          />
        </CardImageContainer>

        <a
          href={info ? `${urlBase ? '/' + urlBase : ''}${info.url}` : ''}
          target="_blank"
          className="info-container"
        >
          <CardTitulo>{info.titulo}</CardTitulo>
          <CardSubtitulo>
            {info.anoFabricacaoVersao}
            <br />
            {info.quilometragem} km
          </CardSubtitulo>
          <CardPrice>
            {info.valorCombinar ? 'Valor a combinar' : info.valor}
          </CardPrice>
        </a>
      </CardPaper>
    </>
  );
};

export default CardVertical;
