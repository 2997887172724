import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { BannerWithInfoContainer, BannerLogo, BannerSmallImage, BannerText } from './banner-styled';

const BannerWithInfo = ({ logo, banner, endereco }) => {

    return(
        <BannerWithInfoContainer>
            <Hidden smDown>
                <BannerLogo src={logo} />
                <BannerText>
                    {endereco && endereco.telefones && endereco.telefones.map(phone => (
                        <p key={phone.id}>{phone.numeroFormatado}</p>
                    ))}

                    {endereco &&
                        <p>{`${endereco.endereco || ''}, ${endereco.bairro || ''}, ${endereco.cidade || ''} - ${endereco.estado || ''}`}</p>
                    }
                </BannerText>
            </Hidden>
            <BannerSmallImage src={banner} />
        </BannerWithInfoContainer>
    );
};

export default BannerWithInfo;