import React, { Component } from 'react';

// Styles
import { MaterialForm, ExpandButton, ExpandContent, ContentHeader, Subtitulo, Titulo } from './buttonToCard-styled';
import SelectAnuncie from '../select-anuncie/select-anuncie';

export default class ButtonToCard extends Component{
    state = { expanded: false };

    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    expandEvent = (e) => {
        e.preventDefault();
        this.setState({ expanded: !this.state.expanded })
    }

    resize() {
        let currentSize = (window.innerWidth < 768);
        this.setState({ expanded: currentSize });
    }

    render(){
        return(
            <MaterialForm>
                
                {/* Botão */}
                <ExpandButton className={this.state.expanded ? 'open' : ''} showcontent={ this.state.expanded ? '1' : '0' } onClick={this.expandEvent}>
                    <Titulo>
                        Anúncie grátis!
                    </Titulo>
                </ExpandButton>

                {/* Conteúdo */}
                    <ExpandContent showcontent={ this.state.expanded }>
                        <ContentHeader>
                            <Titulo>É rápido e fácil</Titulo>
                        </ContentHeader>
                        <Subtitulo>
                            Seu anúncio em O Popular, Jornal Daqui e na Internet.
                        </Subtitulo>

                        {this.state.expanded && <SelectAnuncie /> }   
                    </ExpandContent>
            </MaterialForm>
        )
    }

}