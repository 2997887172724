import styled from 'styled-components';
import React from 'react';

export const UnidadesContainer = styled.div`
    width: 100%;
    padding: 20px 0;

    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#FFF'};

    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;

    @media screen and (max-width: 768px){
        flex-direction: column;
        align-items: center;
    }
`;

export const UnidadeItem = styled.div`
    width: 300px;
    margin-left: 10px;
    margin-right: 10px;

    margin-top: 20px;
    &:first-child{ margin-top: 0; }
    
    .titulo {
        font-size: 21px;
        font-weight: bold;
        color: ${props => props.textColor ? props.textColor : '#000'};
        margin-top: 0;
        margin-bottom: 5px;
    }

    .text {
        font-size: 18px;
        color: ${props => props.textColor ? props.textColor : '#000'};

        display: flex;
        align-items: center;

        margin-top: 0;
        margin-bottom: 0;

        .icon {
            margin-right: 5px;
        }
    }

    media screen and (max-width: 768px){
        margin-bottom: 20px;
    }
`;

export const UnidadeDivider = styled.div`
    width: 100%;
    height: 2px;
    border-bottom: 1px solid ${props => props.color ? props.color : '#000000'};

    margin-bottom: 10px;
`;

export const UnidadeVerMapa = styled(props => (
    <p {...props}>
        <a href={props.href} target={props.target}>{props.children}</a>
    </p>
))`
    margin-top: 10px;

    a{
        font-size: 18px;
        color: ${props => props.color ? props.color : '#000000'};
        text-decoration: underline !important;
    }
`;