import React from 'react';

//Components
import { Container } from './../capa/component/commons/theme-styled';
import Topbar from './../capa/component/topbar/topbar';
import Footer from './../capa/component/footer/footer';
import Copyright from './../capa/component/copyright/copyright';
import { Helmet } from 'react-helmet';

// //Styles
import { Logo, ErroContent, Titulo, Subtitulo } from './Error404-styled';

//Imgs
import LogoClassi from '../../resources/svg/logo-classi.svg';

// FavIcon
import FavIcon32 from './../../resources/imgs/favicons/homescreen-V2_favicon-32x32.png';
import FavIcon76 from './../../resources/imgs/favicons/homescreen-V2_ios-76x76.png';

const Error404 = ({ staticContext = {} }) => {
    staticContext.notFound = true;

    return  (
        <>
            <Helmet>
                <link rel="icon" type="image/png" href={FavIcon32} />
                <link rel="apple-touch-icon" sizes="76x76" href={FavIcon76} />
            </Helmet>
            <Container backgroundColor="#400f50" firstContainer={true} direction="column" noPadding={true}>
                <Topbar withAnchor={false} />
                <ErroContent>
                    <Logo src={LogoClassi} />
                    <Titulo>OPS!</Titulo>
                    <Subtitulo>Página não encontrada</Subtitulo>
                </ErroContent>
            </Container>
            <Footer />
            <Copyright />
        </>
        )

}

export default Error404;