import styled from 'styled-components';

export const CopyrightContainer = styled.div`
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
`;

export const Text = styled.p`
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
`;