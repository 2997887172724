import React, { Component } from 'react';

//Components
import Footer from './component/footer/footer';
import Copyright from './component/copyright/copyright';
import Topbar from './component/topbar/topbar';
import SearchInput from './component/search-input/search-input';
import BuscaDivisor from './component/busca-divisor/busca-divisor';
import CardAnuncio from './component/card-anuncio/card-anuncio';
import GPTComponent from './component/gpt/GPT';
import { Helmet } from 'react-helmet';

//Style
import { Container, Titulo, MoreButton } from './component/commons/theme-styled';
import SelectVitrine from './component/select-vitrine/select-vitrine';
import NoResults from './component/no-results/noResults';
import { getAnunciosGenerico } from './../../api/api';

// FavIcon
import FavIcon32 from './../../resources/imgs/favicons/homescreen-V2_favicon-32x32.png';
import FavIcon76 from './../../resources/imgs/favicons/homescreen-V2_ios-76x76.png';

export default class Busca extends Component {
    constructor(props) {
        super(props);

        this.moreAnuncios = this.moreAnuncios.bind(this);

        this.state = {
            anuncios: [],
            titulo: '',
            inicioPaginacao: 0,
            qntdRegistros: 6,
            resultados: 0,
            showMoreButton: false
        };

        this.searchRef = React.createRef();
        this.handleSearch = this.handleSearch.bind(this);
        this.getAnuncios = this.getAnuncios.bind(this);
    }

    componentDidMount() {
        const { titulo, inicioPaginacao } = this.state;

        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const newTitulo = params.get('texto');

        this.setState({ titulo: newTitulo ? newTitulo : titulo });

        this.getAnuncios(newTitulo, inicioPaginacao);
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.search != prevProps.location.search) {
            const search = this.props.location.search;
            const params = new URLSearchParams(search);
            const newTitulo = params.get('texto');

            this.setState({ anuncios: [], titulo: newTitulo, resultados: 0 });

            this.getAnuncios(newTitulo, 0);
        }
    }

    moreAnuncios() {
        const titulo = this.state.titulo, inicioPaginacao = this.state.anuncios.length;
        this.getAnuncios(titulo, inicioPaginacao);
    };

    getAnuncios = (texto, paginacao) => {
        const filtro = {
            "texto": texto,
            "pagina": 0,
            "qntRegistros": 6,
            "paginado": true,
            "inicioPaginacao": paginacao
        }

        getAnunciosGenerico(filtro).then(response => {
            const { anuncios, resultados } = response;

            if (this.state.inicioPaginacao >= 6) {
                this.setState({
                    anuncios: this.state.anuncios.concat(anuncios),
                    resultados: resultados,
                    inicioPaginacao: this.state.inicioPaginacao + 6
                });
            } else {
                this.setState({ anuncios: anuncios, resultados: resultados, inicioPaginacao: this.state.inicioPaginacao + 6 });
            }

            if (anuncios && this.state.anuncios.length < resultados) {
                this.setState({ showMoreButton: true })
            } else {
                this.setState({ showMoreButton: false })
            }
        });

    };

    handleSearch(value) {
        this.setState({ inicioPaginacao: 0, titulo: value });
        this.getAnuncios(value, 0);
    }

    render() {
        const { titulo } = this.state;

        return (
            <>
                <Helmet>
                    <link rel="icon" type="image/png" href={FavIcon32} />
                    <link rel="apple-touch-icon" sizes="76x76" href={FavIcon76} />
                </Helmet>
                <Container direction="column" noPadding={true}>
                    <Topbar />

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <Container firstContainer={true} direction="column">
                        <Titulo>O que você procura?</Titulo>
                        <SearchInput
                            defaultValue={titulo}
                            handleSearch={this.handleSearch}
                            ref={this.searchRef}
                            preventClick={true}
                        />
                    </Container>

                    <BuscaDivisor resultados={this.state.resultados} titulo={this.state.titulo} />

                    <Container direction="column" mobileAlign="flex-start">
                        {this.state.anuncios ? this.state.anuncios.map((anuncio, index) => {
                            return <React.Fragment key={`reactfragkey-${index}`}>
                                {(index !== 0 && !(index % 3)) ? <GPTComponent gptId={`card-${index}`} key={`publicidade-${index}`} gpt="megabox" /> : null}

                                <CardAnuncio
                                    anuncioId={anuncio.id}
                                    key={`key-${anuncio.id}`}
                                    detalhes={{
                                        // Carro/Moto
                                        anoFabricacao: anuncio.anoFabricacao || '',
                                        anoVersao: anuncio.anoVersao || '',
                                        quilometragem: anuncio.quilometragem || '',
                                        tipoCombustivel: anuncio.tipoCombustivel || '',
                                        //Imovel
                                        areaUtil: anuncio.areaUtil || '',
                                        qtdeQuartos: anuncio.qtdeQuartos || '',
                                        qtdeVagas: anuncio.qtdeVagas || ''
                                    }}
                                    vitrine={anuncio.secao.value}
                                    vitrineNome={anuncio.secao.label}
                                    titulo={anuncio.titulo}
                                    descricao={anuncio.descricao}
                                    valor={anuncio.valor}
                                    localizacao={(anuncio.localizacao.bairro ? anuncio.localizacao.bairro : anuncio.localizacao.cidade) + ' - ' + anuncio.localizacao.estado}
                                    thumbnail={anuncio.imagens.length > 0 ? anuncio.imagens[0].imageUrl : null}
                                    imageMask={anuncio.imagemMascaraVitrine}
                                    url={anuncio.urlFeirao || anuncio.url}
                                    isFavorite={anuncio.favorito}
                                />
                            </React.Fragment>
                        }) : null}
                        {this.state.anuncios && this.state.anuncios.length == 0 ? <NoResults /> : null}
                    </Container>

                    <Container>
                        {this.state.showMoreButton ?
                            <MoreButton onClick={this.moreAnuncios}>
                                Carregar mais resultados
                            </MoreButton>
                            : null}
                    </Container>

                    <Container direction="column">
                        <Titulo>Refine sua busca:</Titulo>

                        <SelectVitrine />
                    </Container>
                </Container>
                <Footer />
                <Copyright />
            </>
        );

    }

}