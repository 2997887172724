import React, { Component } from 'react';
import { bool } from 'prop-types';

//Styles
import { FavContainer } from './favoritar-styled';
import Favorite from '@material-ui/icons/Favorite';


export default class Favoritar extends Component{
    
    // TODO: Descobrir como está configurado o back end do anuncio favorito e implementar favoritar e desfavoritar
    static propTypes = {
        isFavorite: bool
    };

    static defaultProps = {
        isFavorite: false
    };
    
    render(){

        return(
            <FavContainer favorite={ this.props.isFavorite ? '#e45353' : 'transparent' }>
                <Favorite className="fav-icon" onClick={ this.props.callback }/>
            </FavContainer>
        );

    };
}