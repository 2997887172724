import React from 'react';
import { QuemSomosContainer, Titulo, QuemSomosImg, QuemSomosText } from './quem-somos-styled';

const QuemSomos = ({ imagem, titulo, texto, lineColor }) => {
    return(
        <>
            <QuemSomosContainer>
                <Titulo lineColor={lineColor}>
                    {titulo}
                </Titulo>

                <QuemSomosImg src={imagem}/>
                
                <QuemSomosText>
                    {texto}
                </QuemSomosText>
            </QuemSomosContainer>
        </>
    )
};

export default QuemSomos;