import styled from 'styled-components';

export const GalleryContainer = styled.div`
    width: 100%;

    @media screen and (max-width: 768px){
        width: 100%;
    }; 

    .image-gallery{
        width: 100%;
        height: 380px;

        overflow: hidden;

        .image-gallery-item{
            width: 100%;
            height: 380px;
            object-fit: cover;
        }

        .image-gallery-svg{
            width: 30px;
        }

        .image-gallery-bullet{
            border: none;
            padding: 3px;
            margin-bottom: 5px;
            background-color: #FFF;
            
            &.active{
                background-color: #f3123d;
            }
        }

        .image-gallery-bullets{
            bottom: 0px;
        }

        .image-gallery-icon{
            opacity: 0;

            &.image-gallery-left-nav{
                background: linear-gradient(-90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
            }

            &.image-gallery-right-nav{
                background: linear-gradient(90deg,rgba(255,255,255, 0) 0%,rgba(0,0,0,0.5) 100%);
            }
            

            &:focus{
                outline: none;
            }

            transition: opacity .5s ease;

            // Oculta caso seja mobile
            @media screen and (max-width: 768px){
                display: none;
            }
        }

        &:hover{
            .image-gallery-icon { opacity: 1; }
        }
    }
`;